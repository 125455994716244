<script setup lang="ts">
import PrintIcon from '@/components/PrintIcon.vue'

const handleClick = () => {
  window.print()
}
</script>

<template>
  <button
    @click="handleClick"
    class="flex items-center bg-slate-600 hover:bg-slate-700 text-white px-5 py-2 rounded font-semibold transition-colors duration-200 ease-in-out print:hidden group"
    data-testid="print-button"
  >
    <PrintIcon
      class="w-4 h-4 mr-2 opacity-85 group-hover:opacity-100 transition-opacity"
    />
    {{ $t('StatementView.PrintButton') }}
  </button>
</template>
