export default function formatCurrency(
  number: number,
  currencyCode: string = 'GBP',
  lang: string = 'en-GB'
): string {
  try {
    if (isNaN(number)) throw new Error('Invalid number')

    const pence = number / 100

    return Intl.NumberFormat(lang, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'narrowSymbol'
    }).format(pence)
  } catch (error) {
    throw new Error('Error formatting currency')
  }
}
