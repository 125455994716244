<template>
  <main class="mb-20">
    <h1
      class="text-xl md:text-2xl font-headers font-semibold text-center text-pretty mb-5"
    >
      {{ $t('Generic.Error.Title') }}
    </h1>
    <p class="text-center text-balance m-0 font-bold" data-testid="description">
      {{ $t('Generic.Error.Description') }}
    </p>
  </main>
</template>
