export default {
  Generic: {
    Error: {
      Title: 'There was an error processing your request',
      Description:
        'Please try again by visiting the link in your email or if the problem persists you can reply to the email for help.'
    }
  },
  StatementView: {
    SwiftaidLogoAlt: 'Swiftaid by Streeva logo',
    ScreenReaderTitle: 'Swiftaid Donor Statements Homepage',
    Title: 'Your Gift Aid statement for tax year ending {statementperiodend}',
    Description:
      'Please find below the total value of donations made by you that included a Gift Aid declaration given by Swiftaid, your donor intermediary, for the tax year starting on {statementperiodstart} and ending on {statementperiodend}.',
    HighlightedSection: {
      Title: 'Gift Aided donations: {donated}',
      Description:
        'This is the total amount of donations you made that Swiftaid added Gift Aid to this year.',
      TotalGiftAid:
        'The total amount of Gift Aid that charities may claim on your Gift Aided donations is {giftaid}.',
      RequestDetailedStatementText: 'Click here to request a detailed statement'
    },
    NoticeSection: {
      Title: 'Please note:',
      Para1:
        'This statement does not include any Gift Aid declarations given by other donor intermediaries on your behalf or given by you directly to a charity.',
      Para2:
        'If you paid less Income Tax and Capital Gains Tax in the tax year than the amount of Gift Aid claimed by charities on all your donations in that tax year, it is your responsibility to pay any difference.'
    },
    PrintButton: 'Print statement',
    RequestDetailedStatementSection: {
      Title: 'Request a detailed statement',
      Description:
        'Request a detailed version of your statement, including a breakdown of each donation that was included, delivered directly to your email.'
    },
    UpdateDetailsSection: {
      Title: 'Update your details',
      Description: 'Update your name or address here.'
    },
    RenewAuthorisationSection: {
      Title: 'Renew Swiftaid authorisation',
      Description:
        'Renew your Swiftaid authorisation and continue to support charities through Gift Aid. It’s quick, free, and only takes a minute.'
    },
    MainFooter: {
      GiftAidItLogoAlt: 'Gift Aid it logo',
      HmrcRecognisedLogoAlt: 'HMRC recognised logo',
      Iso27001LogoAlt: 'ISO 27001 logo',
      Iso22301LogoAlt: 'ISO 22301 logo',
      CompanyInfo:
        'Copyright © {year} Streeva Ltd ("Swiftaid") registered in England & Wales. Company No. 09451226'
    }
  },
  RequestDetailedStatementView: {
    Title: 'Request your detailed statement',
    Description: 'We’ll email your statement to {emailaddress}.',
    SendRequestButtonText: 'Send request',
    SendingRequestButtonText: 'Sending...',
    RequestErrors: {
      BadRequest: 'Whoops, something looks wrong with the data being sent.',
      Unauthorized: 'Whoops, you are not authorised to send this request.',
      NotFound: 'Whoops, the requested resource was not found.',
      TooManyRequests:
        'Whoops, we have had too many requests from this email address.',
      Server: 'Whoops, there was an error on the server side.',
      Unknown: 'Whoops, something went wrong.'
    },
    ContactSupport: 'Contact support here.',
    BackLink: 'back'
  },
  DetailedStatementSentView: {
    Title: 'Your detailed statement should arrive to your email shortly',
    Description:
      'We sent an email to {emailaddress}. If you can’t find the email please check your junk or spam folder 📁',
    HavingTrouble: 'Having trouble?',
    ContactSupport: 'Contact support here',
    BackLink: 'back to statement'
  },
  TipsSection: {
    Title: 'Every tip helps us do more',
    Description: {
      Greeting: 'Dear {name}',
      Para1:
        "Every year, we streamline Gift Aid for millions of donations, saving UK donors like you time and hassle. By managing and compiling Gift Aid for donors and sending statements each year at no cost, we're committed to keeping the process simple and accessible.",
      Para2:
        'If you value the work we do, please consider showing your appreciation with a tip. Your support helps us continue making Gift Aid easy for everyone.',
      SignOff: 'Team Swiftaid'
    },
    ButtonText: 'Send Swiftaid a Tip'
  }
}
