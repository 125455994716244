import { type RouteLocationNormalizedLoaded } from 'vue-router'
import { type CompressedDonorDataRouteQuery } from '@/types'
import { RouteQueryProcessor } from '@/utils/RouteQueryProcessor'

const routeQueryProcessor = new RouteQueryProcessor()

export function useRouteQueryProcessor(route: RouteLocationNormalizedLoaded) {
  const routeQuery = route.query as CompressedDonorDataRouteQuery
  routeQueryProcessor.setRouteQuery(routeQuery)
  routeQueryProcessor.processRouteQuery()

  return {
    routeQuery,
    hasRouteError: routeQueryProcessor.hasError,
    donorData: routeQueryProcessor.donorData
  }
}
