<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="112" height="148">
    <g fill="none" fill-rule="evenodd">
      <path
        fill="#0B2D42"
        fill-rule="nonzero"
        d="M107.988 131.727c0-4.918-23.242-8.905-51.913-8.905-28.67 0-51.913 3.987-51.913 8.905 0 3.316 10.577 6.208 26.252 7.74.144 4.72 12.086 8.533 26.8 8.533 14.806 0 26.81-3.859 26.81-8.619 0-.05-.01-.1-.013-.15 14.414-1.582 23.977-4.35 23.977-7.504Z"
        opacity=".1"
      />
      <path
        fill="#FFF"
        fill-rule="nonzero"
        stroke="#0B2D42"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4.992"
        d="M3.726 86.694c-3.998 26.85 7.852 46.713 52.35 46.713 47.829 0 55.348-17.573 52.35-46.713-2.98-28.983-23.437-52.76-52.35-52.76-28.912 0-48.06 23.944-52.35 52.76Z"
      />
      <g fill-rule="nonzero">
        <path
          fill="#EAEAEA"
          d="M81.936 88.878c-.159-4.352-1.285-6.434-4.724-8.291-3.316-1.79-3.702-3.133-3.696-4.337.005-.692.029-2.192.029-2.192H41.422s.024 1.5.028 2.19c.008 1.205-.38 2.547-3.695 4.338-3.44 1.857-4.566 3.94-4.725 8.291-.227 6.204 0 47.62 0 47.62.018 3.797 10.96 8.074 24.456 8.074 13.496 0 24.456-4.283 24.456-8.085h-.006c.004-.727.225-41.46 0-47.609Z"
        />
        <path
          fill="#D8D8D8"
          d="M76.154 82.348c-.843-.456-3.325-2.051-4.368-3.503-.257-.356-.363-1.098-.4-1.85-.054-1.1.04-2.224.04-2.224s-12.623 3.061-28.484-.232c0 0 .04.676-.022 2.38-.013.376-.033.802-.057 1.282-.125 2.37-3.82 3.923-4.23 4.145-2.807 1.515-3.612 2.958-3.75 6.711-.195 5.323-.048 37.454-.008 45.455h.002l.008 1.802c0 .848 4.676 6.252 22.57 6.252 18.435 0 22.449-5.353 22.449-6.207v-.057c.002-.41.224-41.173.001-47.245-.136-3.754-.942-5.196-3.75-6.71h-.001Z"
        />
        <path
          fill="#231F20"
          d="M41.228 73.6a.605.605 0 0 0-.593.617c0 .015.025 1.497.029 2.174.006.946-.25 2.096-3.376 3.775-3.601 1.935-4.874 4.145-5.043 8.761-.225 6.114-.003 46.983 0 47.395a.605.605 0 0 0 .602.604h.003a.605.605 0 0 0 .6-.611c-.003-.412-.225-41.256 0-47.343.154-4.196 1.184-6.004 4.406-7.735 3.542-1.902 4.023-3.457 4.014-4.854-.004-.683-.028-2.172-.028-2.187a.602.602 0 0 0-.612-.598l-.002.002ZM77.31 80.168c-3.125-1.68-3.38-2.829-3.376-3.775.004-.677.028-2.16.028-2.175a.603.603 0 1 0-1.204-.02c0 .016-.025 1.505-.028 2.189-.008 1.396.473 2.951 4.014 4.854 3.223 1.73 4.252 3.538 4.406 7.734.223 6.088.002 46.931 0 47.343a.606.606 0 0 0 .6.611h.003c.331 0 .6-.27.602-.603.002-.412.224-41.282 0-47.395-.17-4.617-1.442-6.827-5.044-8.762v-.001Z"
        />
        <path
          fill="#231F20"
          d="M81.758 135.703a.604.604 0 0 0-.602.608c0 3.178-7.4 7.654-23.853 7.654-9.369 0-15.044-1.453-18.155-2.672-3.569-1.397-5.7-3.26-5.7-4.982a.605.605 0 0 0-.601-.608.606.606 0 0 0-.603.608c0 2.276 2.357 4.504 6.468 6.115 3.206 1.256 9.036 2.753 18.591 2.753 16.45 0 25.058-4.461 25.058-8.868a.605.605 0 0 0-.603-.608ZM74.916 130.928c-4.689-1.337-10.944-2.073-17.614-2.073-5.276 0-10.31.46-14.559 1.332a.607.607 0 0 0 .24 1.19c4.172-.855 9.123-1.308 14.319-1.308 6.562 0 12.701.72 17.286 2.027a.605.605 0 0 0 .744-.418.608.608 0 0 0-.416-.75Z"
        />
        <path
          fill="#099296"
          stroke="#0B2D42"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width=".998"
          d="M58.007 70.006a96.75 96.75 0 0 1-6.538-.201c-2.654-.178-4.744-.458-6.386-.776-4.773-.928-5.752-2.18-5.752-2.18v5.666s1.112 1.246 5.752 2.192c1.648.337 3.74.634 6.386.826 1.916.138 4.122.221 6.66.221 15.904 0 17.69-3.968 17.69-3.968l.03-5.444s-3.141 3.664-17.841 3.664h-.001Z"
        />
        <path
          fill="#231F20"
          d="M75.687 71.043a.605.605 0 0 0-.603.607c0 .466-1.041 1.493-4.945 2.38-3.449.785-8.044 1.218-12.94 1.218-4.894 0-9.462-.43-12.908-1.21-3.905-.883-4.965-1.911-4.975-2.379a.605.605 0 0 0-.602-.594h-.013a.605.605 0 0 0-.59.62c.033 1.466 2.023 2.656 5.917 3.538 3.53.799 8.208 1.239 13.173 1.239 4.964 0 9.67-.443 13.204-1.246 3.904-.888 5.884-2.087 5.884-3.565a.604.604 0 0 0-.602-.607Z"
        />
        <path
          fill="#E8A623"
          d="M45.204 128.466c-5.65.07-7.558 1.272-7.558 1.272l.047 2.779s1.288 1.319 7.44 1.272c6.152-.047 7.701-1.495 7.701-1.495v-2.627s-1.978-1.272-7.63-1.2v-.001Z"
        />
        <path fill="#F4BF2C" d="m50.627 133.262 1.22-.298v-2.102l-1.22.265z" />
        <path fill="#D38D13" d="m38.457 132.932 1.22.298v-2.136l-1.22-.265z" />
        <path
          fill="#FFF066"
          d="M45.245 128.208c-4.24 0-7.678.81-7.678 1.808 0 .067.014.133.045.196.067.147.206.286.411.42 1.069.695 3.899 1.193 7.222 1.193 3.345 0 6.191-.504 7.242-1.209.282-.187.435-.39.435-.601 0-.999-3.439-1.808-7.678-1.808Z"
        />
        <path
          fill="#FFC31A"
          d="M45.235 129.113c-3.53 0-6.421.553-6.421 1.131s2.862 1.047 6.393 1.047c3.53 0 6.393-.469 6.393-1.047s-2.835-1.131-6.366-1.131Z"
        />
        <path
          fill="#231F20"
          d="M51.347 128.363a.486.486 0 0 0-.238.941c1.481.381 1.523.727 1.523.73 0 .2-.025 1.772-.058 2.196-.51.56-3.224 1.289-7.337 1.289-4.197 0-6.773-.736-7.32-1.272-.042-.425-.077-1.956-.077-2.205.032-.115.49-.526 2.106-.883 1.433-.316 3.312-.49 5.29-.49 1.607 0 3.147.114 4.451.33a.485.485 0 0 0 .156-.96c-1.355-.224-2.948-.342-4.606-.342-3.105 0-8.36.492-8.36 2.337 0 .059.009.644.028 1.233.041 1.299.077 1.349.173 1.483.865 1.202 4.72 1.74 8.159 1.74 3.526 0 7.43-.56 8.204-1.81.08-.13.104-.169.134-1.438.013-.57.02-1.153.02-1.207 0-.97-1.2-1.402-2.248-1.671v-.001Z"
        />
        <path
          fill="#FFF066"
          d="M47.024 130.217a1.961 1.961 0 0 0-.53-.134c-.225-.031-.623-.07-1.194-.12a42.218 42.218 0 0 1-.711-.065 1.292 1.292 0 0 1-.309-.068c-.066-.027-.099-.057-.099-.09 0-.053.079-.099.238-.138.157-.04.421-.058.792-.058.313 0 .555.021.727.065a.59.59 0 0 1 .26.131c.028.029.126.049.22.045l.451-.02c.092-.004.149-.03.125-.06 0 0-.1-.123-.235-.182-.134-.058-.342-.104-.624-.138a5.956 5.956 0 0 0-.296-.028c-.09-.006-.166-.035-.166-.065v-.107c0-.03-.076-.054-.17-.054h-.522c-.093 0-.17.024-.17.054v.1c0 .03-.075.059-.166.064 0 0-.074.004-.188.015a3.79 3.79 0 0 0-.512.075c-.167.038-.3.086-.397.143-.097.058-.145.12-.145.188 0 .074.06.142.177.203.118.061.29.11.519.144.228.034.638.075 1.229.123.438.035.713.064.824.087.157.033.237.079.237.137 0 .065-.09.121-.269.17-.178.047-.453.071-.824.071-.367 0-.652-.027-.857-.081-.204-.054-.325-.182-.325-.182-.027-.029-.124-.048-.216-.044l-.462.023c-.092.005-.098.036-.069.064 0 0 .127.176.562.293.33.089.718.115.718.115.09.008.164.037.164.067v.067c0 .03.077.055.17.055h.523c.093 0 .17-.025.17-.055v-.064c0-.03.074-.059.165-.065 0 0 .27-.016.464-.044.165-.023.309-.052.43-.085.096-.027.18-.057.248-.09.157-.076.235-.157.235-.242 0-.088-.064-.161-.192-.22Z"
        />
        <path
          fill="#E8A623"
          d="M47.405 132.776c-5.65.07-7.558 1.272-7.558 1.272l.048 2.78s1.288 1.319 7.44 1.272c6.151-.047 7.7-1.496 7.7-1.496v-2.626s-1.978-1.272-7.63-1.2v-.002Z"
        />
        <path fill="#F4BF2C" d="m52.829 137.573 1.22-.298v-2.102l-1.22.265z" />
        <path fill="#D38D13" d="m40.659 137.243 1.22.298v-2.136l-1.22-.265z" />
        <path
          fill="#FFF066"
          d="M47.446 132.519c-4.24 0-7.677.809-7.677 1.807 0 .067.014.133.044.197.068.146.206.286.411.42 1.07.695 3.899 1.193 7.222 1.193 3.345 0 6.191-.504 7.243-1.209.281-.187.434-.39.434-.602 0-.998-3.439-1.807-7.678-1.807h.001Z"
        />
        <path
          fill="#FFC31A"
          d="M47.436 133.423c-3.53 0-6.421.553-6.421 1.132 0 .578 2.862 1.047 6.393 1.047 3.53 0 6.393-.469 6.393-1.047 0-.579-2.835-1.132-6.366-1.132h.001Z"
        />
        <path
          fill="#231F20"
          d="M53.549 132.673a.486.486 0 0 0-.238.941c1.48.382 1.522.727 1.522.73 0 .2-.025 1.773-.058 2.197-.51.56-3.223 1.288-7.337 1.288-4.197 0-6.773-.735-7.319-1.272-.043-.424-.077-1.955-.078-2.204.032-.115.49-.526 2.107-.883 1.433-.316 3.311-.49 5.29-.49 1.607 0 3.146.114 4.45.33a.485.485 0 0 0 .156-.96c-1.354-.224-2.948-.342-4.606-.342-3.105 0-8.36.492-8.36 2.337 0 .058.009.643.028 1.232.041 1.3.077 1.35.173 1.483.865 1.203 4.721 1.741 8.159 1.741 3.526 0 7.43-.56 8.205-1.811.08-.129.104-.168.133-1.438.015-.569.021-1.152.021-1.206 0-.97-1.201-1.402-2.248-1.672Z"
        />
        <path
          fill="#FFF066"
          d="M49.225 134.527a1.961 1.961 0 0 0-.53-.135c-.226-.03-.624-.07-1.194-.118a42.218 42.218 0 0 1-.712-.066 1.292 1.292 0 0 1-.309-.068c-.065-.027-.098-.057-.098-.09 0-.053.08-.099.238-.138.158-.04.422-.059.792-.059.313 0 .556.022.728.066a.59.59 0 0 1 .259.131c.028.029.127.049.22.044l.451-.019c.093-.004.149-.03.126-.06 0 0-.102-.123-.235-.182-.135-.058-.342-.104-.625-.139-.142-.017-.295-.027-.295-.027-.092-.006-.166-.035-.166-.065v-.107c0-.03-.077-.054-.17-.054h-.523c-.093 0-.17.024-.17.054v.1c0 .03-.075.058-.166.063l-.188.015a3.79 3.79 0 0 0-.512.076c-.167.037-.3.086-.396.143-.097.058-.146.12-.146.187 0 .075.06.143.177.204.118.06.29.11.52.144.228.034.637.076 1.228.123.439.035.713.064.824.087.158.033.237.078.237.137 0 .064-.09.121-.269.169-.178.048-.453.072-.823.072-.367 0-.653-.027-.857-.081-.205-.055-.326-.183-.326-.183-.026-.028-.124-.047-.216-.043l-.461.023c-.093.005-.098.036-.07.064 0 0 .128.176.562.293.33.089.718.115.718.115.09.008.165.037.165.067v.067c0 .03.076.055.17.055h.522c.093 0 .17-.025.17-.055v-.064c0-.03.074-.059.166-.065 0 0 .27-.016.463-.044.165-.023.309-.052.431-.085.096-.027.179-.057.247-.09.157-.076.235-.157.235-.243 0-.088-.063-.16-.191-.219h-.001Z"
        />
        <path
          fill="#D38D13"
          d="M67.668 129.194c-5.65.07-7.558 1.272-7.558 1.272l.047 2.779s1.288 1.319 7.44 1.272c6.152-.047 7.701-1.495 7.701-1.495v-2.627s-1.978-1.272-7.63-1.2v-.001Z"
        />
        <path fill="#F4BF2C" d="m73.091 133.991 1.22-.298v-2.103l-1.22.265z" />
        <path fill="#BF7704" d="m60.921 133.66 1.22.298v-2.136l-1.22-.265z" />
        <path
          fill="#FFF066"
          d="M67.709 128.937c-4.24 0-7.678.809-7.678 1.807 0 .067.014.133.045.197.067.147.206.286.411.42 1.069.695 3.899 1.193 7.222 1.193 3.345 0 6.191-.504 7.242-1.209.282-.187.435-.39.435-.601 0-.999-3.439-1.808-7.678-1.808Z"
        />
        <path
          fill="#FFC31A"
          d="M67.699 129.84c-3.53 0-6.421.554-6.421 1.132 0 .578 2.862 1.047 6.393 1.047 3.53 0 6.393-.469 6.393-1.047s-2.835-1.131-6.366-1.131Z"
        />
        <path
          fill="#231F20"
          d="M73.811 129.09a.486.486 0 0 0-.238.941c1.481.382 1.523.728 1.523.731 0 .2-.025 1.772-.058 2.196-.51.56-3.224 1.289-7.337 1.289-4.197 0-6.773-.736-7.32-1.272-.042-.425-.077-1.956-.077-2.205.032-.115.49-.526 2.106-.883 1.433-.316 3.312-.49 5.29-.49 1.608 0 3.147.114 4.451.33a.485.485 0 0 0 .156-.96c-1.355-.224-2.948-.342-4.606-.342-3.105 0-8.36.492-8.36 2.337 0 .059.009.644.028 1.233.041 1.299.077 1.349.173 1.483.865 1.202 4.72 1.74 8.159 1.74 3.526 0 7.43-.56 8.204-1.81.08-.13.104-.169.134-1.438.013-.57.02-1.153.02-1.207 0-.97-1.2-1.402-2.248-1.67v-.002Z"
        />
        <path
          fill="#FFF066"
          d="M69.488 130.945a1.961 1.961 0 0 0-.531-.134c-.225-.031-.623-.07-1.193-.12a31.664 31.664 0 0 1-.712-.065 1.292 1.292 0 0 1-.309-.07c-.065-.026-.099-.056-.099-.089 0-.052.08-.098.238-.137.158-.04.422-.058.792-.058.313 0 .556.021.727.065a.59.59 0 0 1 .26.131c.028.029.127.049.22.045l.451-.02c.092-.004.149-.03.126-.06 0 0-.1-.123-.235-.182-.135-.058-.342-.104-.625-.138-.142-.018-.295-.028-.295-.028-.092-.006-.166-.035-.166-.065v-.107c0-.03-.077-.054-.17-.054h-.523c-.093 0-.17.024-.17.054v.1c0 .03-.075.059-.166.064 0 0-.074.004-.188.015a3.79 3.79 0 0 0-.512.075 1.407 1.407 0 0 0-.396.143c-.098.058-.146.12-.146.188 0 .074.06.142.177.203.118.061.29.11.52.144.227.034.637.075 1.228.123.439.035.713.064.824.087.158.033.237.079.237.137 0 .065-.09.121-.268.17-.18.047-.453.071-.824.071-.367 0-.652-.027-.857-.081a.743.743 0 0 1-.324-.182c-.027-.029-.124-.048-.216-.044l-.462.023c-.092.005-.098.036-.069.064 0 0 .127.176.562.293a4.4 4.4 0 0 0 .718.115c.09.008.164.037.164.067v.067c0 .03.077.055.17.055h.523c.093 0 .17-.025.17-.055v-.064c0-.03.074-.059.165-.065 0 0 .27-.016.464-.044.165-.023.308-.052.43-.085.096-.027.18-.057.248-.09.157-.076.235-.157.235-.242 0-.088-.064-.161-.192-.22h-.001Z"
        />
        <path
          fill="#07C5D1"
          stroke="#0B2D42"
          stroke-width=".998"
          d="M57.303 62.072c-9.458 0-17.249 1.61-18.271 3.683a1.021 1.021 0 0 0-.114.464c0 2.29 8.232 4.146 18.385 4.146 10.153 0 18.384-1.856 18.384-4.146 0-.158-.038-.312-.114-.464-1.021-2.073-8.812-3.683-18.27-3.683Z"
        />
        <path
          fill="#231F20"
          d="M38.714 65.529a.605.605 0 0 0-.603.608v5.514c0 .335.27.607.603.607a.604.604 0 0 0 .602-.607v-5.514a.605.605 0 0 0-.602-.608Z"
        />
        <path
          fill="#E8A623"
          d="M60.858 134.079c-5.953.074-7.963 1.34-7.963 1.34l.05 2.928s1.357 1.39 7.838 1.34c6.48-.05 8.113-1.576 8.113-1.576v-2.767s-2.085-1.34-8.038-1.265Z"
        />
        <path fill="#F4BF2C" d="m66.572 139.132 1.285-.314v-2.215l-1.285.28z" />
        <path fill="#D38D13" d="m53.75 138.784 1.285.314v-2.25l-1.285-.279z" />
        <path
          fill="#FFF066"
          d="M60.902 133.807c-4.468 0-8.09.853-8.09 1.905 0 .07.016.14.048.207.07.154.217.302.433.442 1.126.733 4.107 1.258 7.608 1.258 3.523 0 6.522-.531 7.63-1.273.296-.198.457-.411.457-.634 0-1.052-3.622-1.905-8.088-1.905h.002Z"
        />
        <path
          fill="#FFC31A"
          d="M60.892 134.76c-3.72 0-6.765.584-6.765 1.193 0 .61 3.015 1.103 6.735 1.103s6.736-.494 6.736-1.103c0-.61-2.986-1.192-6.706-1.192Z"
        />
        <path
          fill="#231F20"
          d="M67.324 133.996a.486.486 0 0 0-.238.941c1.605.413 1.624.79 1.624.794 0 .211-.026 1.887-.062 2.323-.531.596-3.402 1.374-7.756 1.374-4.441 0-7.166-.785-7.735-1.355-.048-.449-.083-2.132-.083-2.34.02-.099.447-.546 2.184-.937 1.52-.342 3.52-.53 5.634-.53 1.695 0 3.319.12 4.694.348a.485.485 0 0 0 .156-.96c-1.427-.235-3.103-.36-4.85-.36-3.261 0-8.782.513-8.782 2.436 0 .06.01.678.03 1.298.043 1.36.083 1.415.179 1.547.905 1.26 4.959 1.824 8.573 1.824 3.709 0 7.813-.586 8.623-1.897.08-.131.105-.17.137-1.501.014-.6.022-1.214.022-1.271 0-1.003-1.255-1.454-2.35-1.735Z"
        />
        <path
          fill="#FFF066"
          d="M62.776 135.924a2.05 2.05 0 0 0-.56-.142 28.476 28.476 0 0 0-1.256-.126c-.415-.036-.665-.06-.75-.07a1.347 1.347 0 0 1-.326-.071c-.069-.029-.103-.06-.103-.095 0-.055.083-.103.25-.144.166-.04.445-.062.834-.062.33 0 .585.024.766.07a.627.627 0 0 1 .274.138c.03.03.133.05.231.047l.476-.021c.097-.004.157-.033.132-.063 0 0-.106-.13-.247-.193-.142-.061-.36-.11-.658-.146-.15-.018-.311-.03-.311-.03-.097-.006-.175-.037-.175-.068v-.112c0-.031-.08-.057-.178-.057h-.55c-.099 0-.18.026-.18.057v.106c0 .031-.08.061-.175.066 0 0-.078.005-.198.016a4.244 4.244 0 0 0-.54.079c-.176.04-.315.09-.417.151-.103.061-.153.127-.153.198 0 .078.062.15.186.214.124.065.306.116.547.152.24.037.672.08 1.295.13.462.037.751.068.867.092.167.035.25.083.25.144 0 .068-.094.127-.283.178-.188.05-.478.077-.868.077-.387 0-.687-.029-.902-.086-.216-.057-.342-.191-.342-.191-.029-.03-.13-.05-.228-.046l-.487.024c-.097.005-.103.037-.072.068 0 0 .134.185.592.308.347.093.757.12.757.12.095.008.173.04.173.071v.07c0 .031.08.057.179.057h.55c.098 0 .179-.026.179-.057v-.067c0-.032.079-.062.175-.068 0 0 .285-.018.488-.046.175-.024.326-.055.454-.09.101-.029.187-.06.26-.095.166-.08.248-.165.248-.255 0-.092-.067-.17-.203-.232h-.001Z"
        />
        <path
          fill="#E8A623"
          d="M58.772 125.82c-6.069.074-8.117 1.34-8.117 1.34l.051 2.928s1.383 1.389 7.99 1.34c6.606-.05 8.27-1.576 8.27-1.576v-2.767s-2.125-1.34-8.194-1.266Z"
        />
        <path fill="#F4BF2C" d="m64.596 130.874 1.31-.314v-2.215l-1.31.28z" />
        <path fill="#D38D13" d="m51.527 130.525 1.31.314v-2.25l-1.31-.279z" />
        <path
          fill="#FFF066"
          d="M58.816 125.548c-4.554 0-8.245.853-8.245 1.905 0 .07.015.14.048.207.072.154.22.302.441.442 1.148.733 4.187 1.257 7.756 1.257 3.592 0 6.648-.53 7.778-1.272.302-.198.466-.411.466-.634 0-1.052-3.692-1.905-8.244-1.905Z"
        />
        <path
          fill="#FFC31A"
          d="M58.806 126.502c-3.793 0-6.897.583-6.897 1.192 0 .61 3.074 1.103 6.867 1.103 3.792 0 6.866-.494 6.866-1.103 0-.61-3.045-1.192-6.836-1.192Z"
        />
        <path
          fill="#231F20"
          d="M65.358 125.736a.486.486 0 0 0-.234.942c1.64.414 1.66.79 1.66.793 0 .212-.027 1.883-.063 2.321-.544.598-3.475 1.377-7.915 1.377-4.532 0-7.313-.786-7.895-1.358-.049-.45-.084-2.13-.084-2.335.028-.123.503-.56 2.25-.944 1.548-.338 3.581-.525 5.729-.525 1.729 0 3.383.12 4.787.348a.485.485 0 0 0 .153-.96c-1.454-.235-3.162-.36-4.941-.36-3.322 0-8.942.513-8.942 2.436 0 .062.01.678.03 1.298.044 1.362.084 1.417.182 1.55.922 1.257 5.049 1.82 8.73 1.82 3.776 0 7.955-.584 8.78-1.892.082-.133.107-.172.14-1.505.015-.6.022-1.214.022-1.271 0-1.005-1.277-1.456-2.39-1.736h.001Z"
        />
        <path
          fill="#FFF066"
          d="M60.726 127.665a2.125 2.125 0 0 0-.57-.142c-.242-.033-.669-.075-1.28-.126-.423-.036-.678-.06-.765-.07a1.388 1.388 0 0 1-.33-.071c-.071-.029-.106-.06-.106-.095 0-.055.084-.103.255-.144.17-.041.453-.062.85-.062.337 0 .598.024.782.07.184.046.278.138.278.138.031.03.137.05.236.047l.485-.021c.099-.005.16-.033.135-.063 0 0-.108-.13-.253-.193-.144-.062-.368-.11-.67-.146-.154-.019-.318-.03-.318-.03-.098-.006-.179-.037-.179-.068v-.112c0-.031-.082-.057-.181-.057h-.562c-.1 0-.182.026-.182.057v.106c0 .031-.08.061-.179.066 0 0-.08.004-.202.015a4.376 4.376 0 0 0-.55.079c-.179.04-.32.09-.425.151-.104.06-.156.127-.156.198 0 .078.063.15.19.214.126.065.312.116.558.152.245.037.685.08 1.32.13.47.037.766.068.883.092.17.035.255.083.255.144 0 .068-.096.127-.288.178-.192.052-.487.077-.884.077-.394 0-.7-.029-.92-.086-.221-.057-.35-.191-.35-.191-.029-.03-.133-.05-.232-.046l-.496.024c-.099.005-.105.038-.074.068 0 0 .136.185.603.308.354.094.771.122.771.122.098.007.177.04.177.07v.07c0 .031.083.057.182.057h.561c.101 0 .182-.026.182-.057v-.067c0-.032.08-.062.179-.068 0 0 .29-.018.497-.046.178-.024.332-.055.463-.09.103-.03.192-.06.265-.095.17-.08.253-.165.253-.255 0-.092-.069-.17-.207-.232Z"
        />
        <path
          fill="#E8A623"
          d="M49.262 129.12c-5.954.074-7.963 1.34-7.963 1.34l.05 2.927s1.357 1.39 7.837 1.34c6.48-.05 8.114-1.576 8.114-1.576v-2.766s-2.085-1.34-8.039-1.266h.001Z"
        />
        <path
          fill="#F4BF2C"
          d="m54.976 134.173 1.285-.314v-2.215l-1.285.278z"
        />
        <path fill="#D38D13" d="m42.154 133.824 1.285.314v-2.25l-1.285-.279z" />
        <path
          fill="#FFF066"
          d="M49.305 128.848c-4.467 0-8.088.853-8.088 1.905 0 .07.015.14.047.207.07.154.217.302.433.441 1.126.734 4.107 1.258 7.608 1.258 3.523 0 6.522-.53 7.63-1.273.296-.197.457-.41.457-.633 0-1.052-3.622-1.905-8.088-1.905h.001Z"
        />
        <path
          fill="#FFC31A"
          d="M49.295 129.8c-3.72 0-6.765.584-6.765 1.193 0 .61 3.016 1.103 6.736 1.103 3.72 0 6.736-.494 6.736-1.103 0-.61-2.987-1.193-6.706-1.193Z"
        />
        <path
          fill="#231F20"
          d="M55.728 129.036a.486.486 0 0 0-.238.941c1.605.413 1.624.79 1.624.793 0 .212-.026 1.888-.062 2.324-.532.595-3.402 1.373-7.756 1.373-4.441 0-7.166-.784-7.736-1.355-.047-.449-.082-2.132-.083-2.34.02-.099.447-.546 2.184-.937 1.52-.342 3.52-.53 5.634-.53 1.695 0 3.319.12 4.694.348a.485.485 0 0 0 .156-.96c-1.427-.235-3.103-.36-4.85-.36-3.261 0-8.782.513-8.782 2.436 0 .06.01.678.03 1.298.043 1.36.083 1.415.179 1.547.906 1.26 4.959 1.824 8.573 1.824 3.709 0 7.813-.586 8.623-1.897.08-.131.105-.17.137-1.501.014-.6.022-1.214.022-1.271 0-1.003-1.256-1.455-2.35-1.735l.001.002Z"
        />
        <path
          fill="#FFF066"
          d="M51.18 130.964a2.05 2.05 0 0 0-.56-.143 28.476 28.476 0 0 0-1.257-.125c-.414-.036-.665-.06-.75-.07a1.347 1.347 0 0 1-.325-.072c-.069-.028-.104-.059-.104-.094 0-.055.083-.103.25-.145.167-.04.445-.062.835-.062.33 0 .585.024.766.07a.627.627 0 0 1 .273.138c.03.03.134.05.232.047l.476-.021c.097-.004.157-.033.132-.063 0 0-.106-.13-.248-.193-.142-.061-.36-.11-.657-.146-.15-.018-.312-.03-.312-.03-.096-.006-.174-.037-.174-.068v-.112c0-.031-.08-.057-.178-.057h-.551c-.098 0-.179.026-.179.057v.106c0 .031-.08.061-.176.066 0 0-.078.005-.197.016a4.244 4.244 0 0 0-.54.079c-.176.04-.315.09-.418.151-.102.061-.153.127-.153.198 0 .078.062.15.186.214.124.065.306.116.547.152.24.037.672.08 1.295.13.462.037.751.068.867.092.167.035.25.083.25.144 0 .068-.094.127-.283.178-.188.05-.477.077-.868.077-.387 0-.687-.029-.903-.086-.216-.057-.342-.191-.342-.191-.028-.03-.13-.05-.228-.046l-.486.024c-.097.005-.104.037-.073.068 0 0 .134.185.592.308.348.094.757.122.757.122.095.007.173.04.173.07v.07c0 .031.081.057.179.057h.55c.099 0 .18-.026.18-.057v-.067c0-.032.078-.062.174-.068 0 0 .285-.018.489-.046.174-.025.325-.055.453-.09.101-.03.188-.06.26-.095.166-.08.248-.165.248-.255 0-.093-.067-.17-.203-.232Z"
        />
        <path
          fill="#E8A623"
          d="M71.87 126.13c-4.283-3.714-6.502-4.086-6.502-4.086l-1.789 2.116s.1 1.848 4.743 5.914c4.645 4.066 6.757 4.014 6.757 4.014l1.724-1.97s-.65-2.273-4.932-5.988Z"
        />
        <path
          fill="#D38D13"
          d="m72.787 133.345 1.111.59 1.38-1.577-1.088-.615z"
        />
        <path
          fill="#F4BF2C"
          d="m63.88 124.982.718 1.037L66 124.417l-.74-1.012z"
        />
        <path
          fill="#FFF066"
          d="M72.07 125.966c-3.18-2.828-6.288-4.513-6.944-3.764a.447.447 0 0 0-.095.178c-.046.154-.033.352.032.588.345 1.235 2.14 3.494 4.632 5.71 2.508 2.23 4.973 3.75 6.224 3.923.334.047.582-.002.72-.161.656-.75-1.391-3.649-4.57-6.475Z"
        />
        <path
          fill="#FFC31A"
          d="M71.47 126.637c-2.648-2.354-5.179-3.866-5.558-3.433-.38.434 1.459 2.694 4.106 5.048s5.102 3.912 5.481 3.478c.38-.433-1.382-2.739-4.03-5.093Z"
        />
        <path
          fill="#231F20"
          d="M75.875 130.022a.489.489 0 0 0-.127.676c.86 1.272.665 1.56.662 1.562-.13.15-1.182 1.312-1.485 1.608-.75.08-3.264-1.183-6.348-3.926-3.148-2.799-4.596-5.067-4.653-5.834.247-.347 1.226-1.518 1.388-1.705.1-.065.713-.068 2.16.743 1.281.718 2.804 1.84 4.288 3.16 1.205 1.072 2.285 2.183 3.122 3.215a.48.48 0 0 0 .678.069.489.489 0 0 0 .068-.684c-.87-1.072-1.986-2.223-3.23-3.33-2.328-2.07-6.59-5.205-7.802-3.822-.038.043-.415.489-.788.943-.821 1.002-.827 1.063-.843 1.228-.141 1.478 2.396 4.453 4.975 6.745 2.421 2.153 5.388 4.166 6.947 4.166.144 0 .275-.017.394-.053.144-.043.188-.057 1.045-.988.385-.42.773-.851.808-.891.636-.727.019-1.853-.59-2.753a.48.48 0 0 0-.67-.128Z"
        />
        <path
          fill="#FFF066"
          d="M71.941 127.534c-.095-.108-.203-.217-.203-.217-.064-.066-.102-.138-.082-.16l.07-.08c.02-.022-.021-.091-.091-.154l-.392-.349c-.07-.062-.144-.094-.163-.073l-.066.076c-.02.022-.095-.007-.167-.065 0 0-.058-.046-.15-.113a4.031 4.031 0 0 0-.434-.286 1.378 1.378 0 0 0-.391-.156c-.11-.022-.188-.007-.233.043-.048.055-.049.146 0 .27.048.125.146.277.294.455.149.178.429.482.84.912.307.319.493.524.561.614.097.13.126.218.088.261-.042.049-.147.031-.312-.052-.166-.084-.388-.249-.664-.496-.276-.244-.472-.455-.59-.632a.756.756 0 0 1-.124-.354c-.002-.039-.062-.118-.134-.176l-.361-.29c-.073-.058-.097-.039-.094.002 0 0-.02.217.23.594.188.286.462.565.462.565.064.066.1.137.08.16l-.044.05c-.02.023.022.091.091.154l.392.35c.07.061.143.094.163.072l.042-.049c.02-.022.095.006.167.063 0 0 .214.168.377.276.14.093.265.168.379.224.09.044.171.076.244.097.168.048.28.04.335-.024.057-.066.057-.164 0-.293a1.954 1.954 0 0 0-.31-.456 27.643 27.643 0 0 0-.815-.884 35.344 35.344 0 0 1-.491-.524 1.335 1.335 0 0 1-.187-.257c-.031-.064-.036-.108-.015-.133.034-.04.124-.021.268.055.145.076.355.238.633.485.235.209.402.386.502.534.1.147.108.27.108.27.003.041.064.122.137.18l.35.287c.072.059.133.076.135.04 0 0 .005-.16-.057-.294-.062-.134-.188-.308-.377-.521Z"
        />
        <path
          fill="#D8D8D8"
          d="M41.381 73.538s.046.73-.025 3.347C44.39 78.1 49.57 79.29 58.222 79.29c7.364 0 12-1.055 14.9-2.287-.059-1.688.047-3.418.047-3.418s-14.088 5.014-31.788-.047Z"
        />
        <rect
          width="6.868"
          height="42.867"
          fill="#FFF"
          opacity=".51"
          rx="2.776"
          transform="translate(70.939 93.97)"
        />
        <path
          fill="#231F20"
          d="M75.682 65.529a.604.604 0 0 0-.602.608v5.514c0 .335.27.607.602.607a.605.605 0 0 0 .602-.607v-5.514a.605.605 0 0 0-.602-.608ZM64.14 64.993H49.2a.605.605 0 0 0-.604.607c0 .336.27.608.603.608h14.94a.605.605 0 0 0 .604-.608.605.605 0 0 0-.603-.607Z"
        />
        <path
          fill="#97A0A0"
          d="M57.203 84.76a75.571 75.571 0 0 1-16.114-1.277c-1.435-.27-2.857.629-3.186 2.068-.33 1.436.657 2.89 2.211 3.184a80.773 80.773 0 0 0 17.236 1.367c1.581-.043 2.78-1.325 2.679-2.796-.102-1.473-1.367-2.585-2.826-2.545Z"
        />
        <path
          fill="#FFC31A"
          d="M46.068 139.617c-5.193 0-6.63-1.17-6.778-1.305l-.155-.141-.123-2.988c-.019-.78.828-1.379 2.59-1.84 1.476-.385 3.44-.627 5.527-.678.276-.007.549-.01.818-.01 1.229 0 7.361.102 7.41 2.118l.069 2.845-.156.153c-.17.166-1.831 1.628-7.995 1.826-.424.014-.826.02-1.208.02Z"
        />
        <path
          fill="#0B2D42"
          d="M47.946 133.157c3.861 0 6.89.696 6.914 1.63l.063 2.625s-1.515 1.486-7.664 1.684c-.418.013-.815.02-1.191.02-5.152 0-6.444-1.176-6.444-1.176l-.115-2.778c-.024-.998 3.393-1.891 7.632-1.996.273-.006.541-.01.806-.01m-.001-1.002c-.273 0-.549.003-.83.01-2.124.052-4.127.299-5.64.694-.742.195-3 .785-2.963 2.33l.115 2.794.018.42.31.283c.264.239 1.843 1.434 7.112 1.434.387 0 .794-.007 1.224-.02 6.22-.2 8.027-1.675 8.326-1.968l.311-.306-.01-.437-.064-2.626c-.019-.784-.827-2.608-7.908-2.608h-.001Z"
        />
        <path
          fill="#FFF066"
          d="M47.141 133.167c-4.24.104-7.656.998-7.632 1.996a.446.446 0 0 0 .05.195c.07.145.213.281.421.41 1.085.669 3.926 1.097 7.249 1.015 3.343-.082 6.177-.655 7.211-1.385.278-.194.426-.4.42-.611-.024-.999-3.48-1.724-7.718-1.62Z"
        />
        <path
          fill="#FFC31A"
          d="M47.133 133.872c-3.53.087-6.405.71-6.391 1.289.014.578 2.887.976 6.416.89 3.53-.086 6.38-.625 6.367-1.203-.015-.579-2.862-1.062-6.392-.976Z"
        />
        <path
          fill="#FFC31A"
          d="M43.804 134.742c-5.193 0-6.63-1.171-6.777-1.305l-.156-.14-.122-2.989c-.044-1.83 5.081-2.443 8.116-2.518.277-.006.55-.01.818-.01 1.23 0 7.362.102 7.411 2.118l.07 2.845-.157.153c-.17.166-1.83 1.628-7.995 1.826-.424.014-.825.02-1.207.02Z"
        />
        <path
          fill="#0B2D42"
          d="M45.683 128.282c3.862 0 6.89.696 6.914 1.63l.063 2.625s-1.514 1.486-7.663 1.684c-.419.013-.816.02-1.193.02-5.151 0-6.443-1.176-6.443-1.176l-.115-2.777c-.024-.999 3.392-1.892 7.632-1.996.272-.007.54-.01.805-.01m0-1.004c-.273 0-.549.003-.83.01-2.123.052-4.127.299-5.64.695-.741.194-3 .784-2.963 2.329l.116 2.794.017.421.31.282c.264.24 1.843 1.435 7.111 1.435.387 0 .794-.007 1.224-.02 6.22-.2 8.027-1.675 8.326-1.969l.312-.305-.011-.438-.063-2.625c-.02-.785-.827-2.609-7.909-2.609Z"
        />
        <path
          fill="#FFF066"
          d="M44.878 128.292c-4.24.104-7.656.998-7.632 1.996a.446.446 0 0 0 .05.195c.07.145.212.282.42.41 1.085.67 3.927 1.098 7.25 1.016 3.343-.082 6.176-.656 7.21-1.385.278-.195.426-.401.421-.612-.024-.999-3.48-1.723-7.718-1.62h-.001Z"
        />
        <path
          fill="#FFC31A"
          d="M44.87 128.997c-3.53.087-6.405.71-6.391 1.289.014.578 2.887.976 6.416.89 3.53-.086 6.38-.625 6.367-1.203-.014-.578-2.862-1.062-6.392-.976ZM65.965 136.37c-5.193 0-6.63-1.173-6.777-1.306l-.156-.141-.123-2.988c-.019-.78.828-1.379 2.59-1.84 1.477-.386 3.44-.628 5.527-.679.276-.006.548-.01.816-.01 1.229 0 7.363.102 7.413 2.12l.07 2.844-.157.153c-.17.166-1.83 1.628-7.995 1.826-.424.014-.825.02-1.207.02Z"
        />
        <path
          fill="#0B2D42"
          d="M67.843 129.908c3.862 0 6.892.696 6.914 1.63l.063 2.625s-1.514 1.486-7.663 1.684c-.419.013-.816.02-1.193.02-5.151 0-6.443-1.176-6.443-1.176l-.115-2.777c-.024-.999 3.392-1.892 7.632-1.996.271-.007.54-.01.805-.01m0-1.003c-.272 0-.548.003-.828.01-2.124.052-4.128.299-5.641.695-.742.194-3 .784-2.963 2.329l.116 2.794.017.421.31.282c.264.24 1.843 1.434 7.111 1.434.387 0 .794-.006 1.224-.02 6.22-.2 8.027-1.674 8.326-1.968l.312-.305-.011-.438-.063-2.625c-.02-.785-.827-2.609-7.91-2.609Z"
        />
        <path
          fill="#FFF066"
          d="M67.038 129.918c-4.24.104-7.656.998-7.632 1.996a.446.446 0 0 0 .05.195c.07.145.212.282.42.41 1.085.67 3.927 1.098 7.25 1.016 3.343-.082 6.176-.656 7.21-1.385.278-.195.426-.401.421-.612-.024-.999-3.48-1.723-7.718-1.62h-.001Z"
        />
        <path
          fill="#FFC31A"
          d="M67.03 130.623c-3.53.087-6.405.71-6.39 1.289.013.578 2.886.977 6.415.89 3.53-.086 6.38-.625 6.367-1.203-.014-.578-2.862-1.062-6.392-.976Z"
        />
        <path
          fill="#FFC31A"
          d="M57.435 132.212c-5.193 0-6.63-1.171-6.778-1.305l-.155-.14-.123-2.989c-.044-1.831 5.082-2.443 8.117-2.518.276-.006.549-.01.818-.01 1.229 0 7.362.102 7.41 2.12l.069 2.844-.156.153c-.17.166-1.83 1.628-7.995 1.826-.424.014-.825.02-1.207.02Z"
        />
        <path
          fill="#0B2D42"
          d="M59.314 125.752c3.861 0 6.89.696 6.913 1.63l.064 2.625s-1.515 1.486-7.664 1.684c-.418.013-.815.02-1.192.02-5.152 0-6.444-1.176-6.444-1.176l-.115-2.777c-.024-.999 3.393-1.892 7.632-1.996.273-.007.54-.01.806-.01m0-1.003c-.273 0-.549.003-.83.01-2.124.052-4.127.299-5.64.695-.742.194-3 .784-2.963 2.329l.115 2.794.018.421.31.282c.263.24 1.843 1.434 7.11 1.434.388 0 .795-.006 1.225-.02 6.22-.2 8.027-1.674 8.326-1.968l.311-.305-.01-.438-.064-2.625c-.019-.785-.827-2.609-7.908-2.609Z"
        />
        <path
          fill="#FFF066"
          d="M58.508 125.762c-4.24.104-7.656.998-7.632 1.996a.446.446 0 0 0 .05.195c.07.145.213.282.421.41 1.085.67 3.926 1.098 7.249 1.016 3.343-.082 6.177-.656 7.211-1.385.278-.195.425-.401.42-.612-.024-.999-3.48-1.723-7.718-1.62Z"
        />
        <path
          fill="#FFC31A"
          d="M58.5 126.467c-3.529.087-6.405.71-6.39 1.289.014.578 2.887.977 6.416.89 3.53-.086 6.38-.625 6.366-1.203-.014-.578-2.861-1.062-6.391-.976Z"
        />
        <path
          fill="#FFC31A"
          d="M74.677 135.079c-.236-.008-2.434-.201-6.848-4.542-4.419-4.345-4.61-6.463-4.616-6.69l-.006-.212 2.051-2.16c.14-.143.387-.306.797-.306 1.496 0 4.409 2.238 6.63 4.456 1.483 1.48 2.747 3.013 3.56 4.316.967 1.553 1.176 2.575.636 3.123l-1.986 2.022-.218-.007Z"
        />
        <path
          fill="#0B2D42"
          d="M66.054 121.671c1.124 0 3.7 1.736 6.28 4.31 3.013 3.007 4.89 6.02 4.193 6.73l-1.833 1.866s-2.113-.07-6.517-4.4c-4.403-4.328-4.466-6.347-4.466-6.347l1.906-2.008c.1-.102.248-.151.437-.151m0-1.003c-.582 0-.943.243-1.144.448l-1.92 2.021-.288.305.013.42c.012.405.301 2.646 4.766 7.036 4.455 4.379 6.762 4.67 7.18 4.684l.434.014.306-.311 1.833-1.867c1.079-1.098-.162-3.089-.57-3.743-.83-1.334-2.12-2.899-3.63-4.405-2.228-2.225-5.232-4.602-6.98-4.602Z"
        />
        <path
          fill="#FFF066"
          d="M72.334 125.982c-3.013-3.008-6.02-4.87-6.718-4.16a.441.441 0 0 0-.105.171c-.055.152-.054.35-.001.59.273 1.251 1.936 3.612 4.298 5.969 2.377 2.372 4.75 4.033 5.99 4.278.33.066.58.03.729-.119.697-.71-1.181-3.723-4.193-6.73Z"
        />
        <path
          fill="#FFC31A"
          d="M71.825 126.465c-2.509-2.503-4.949-4.16-5.353-3.749-.403.411 1.303 2.775 3.812 5.278 2.508 2.504 4.87 4.202 5.273 3.79.405-.41-1.223-2.814-3.732-5.319ZM47.863 135.547c-5.193 0-6.63-1.171-6.777-1.304l-.156-.142-.123-2.987c-.044-1.832 5.082-2.444 8.117-2.519.277-.006.55-.01.818-.01 1.23 0 7.362.102 7.411 2.12l.07 2.844-.157.153c-.17.166-1.83 1.628-7.995 1.826-.424.014-.825.02-1.207.02h-.001Z"
        />
        <path
          fill="#0B2D42"
          d="M49.741 129.087c3.862 0 6.89.695 6.914 1.629l.063 2.625s-1.514 1.487-7.663 1.684c-.419.013-.816.02-1.192.02-5.152 0-6.444-1.176-6.444-1.176l-.114-2.777c-.024-.999 3.392-1.892 7.632-1.996.272-.007.54-.01.805-.01m0-1.003c-.274 0-.55.004-.83.01-2.124.052-4.128.299-5.64.695-.742.194-3 .784-2.964 2.329l.116 2.794.017.421.31.282c.264.24 1.843 1.435 7.112 1.435.387 0 .794-.007 1.224-.02 6.22-.2 8.027-1.675 8.326-1.969l.312-.305-.011-.438-.063-2.625c-.02-.784-.827-2.609-7.909-2.609Z"
        />
        <path
          fill="#FFF066"
          d="M48.937 129.097c-4.24.103-7.656.997-7.632 1.996a.446.446 0 0 0 .05.195c.07.144.212.28.42.409 1.085.67 3.927 1.098 7.249 1.016 3.344-.082 6.177-.655 7.212-1.385.277-.194.425-.401.42-.612-.024-.998-3.481-1.723-7.718-1.62h-.001Z"
        />
        <path
          fill="#FFC31A"
          d="M48.93 129.801c-3.53.087-6.406.71-6.392 1.29.014.577 2.887.976 6.416.89 3.53-.087 6.38-.626 6.367-1.204-.015-.578-2.862-1.062-6.392-.976ZM59.713 140.537c-5.193 0-6.63-1.172-6.777-1.306l-.156-.14-.122-2.988c-.02-.78.827-1.38 2.589-1.84 1.477-.386 3.44-.628 5.527-.68.277-.006.55-.01.818-.01 1.23 0 7.362.103 7.411 2.12l.07 2.844-.157.154c-.17.165-1.83 1.627-7.995 1.826-.424.014-.825.02-1.207.02Z"
        />
        <path
          fill="#0B2D42"
          d="M61.592 134.077c3.862 0 6.89.695 6.913 1.63l.063 2.625s-1.514 1.486-7.663 1.683c-.419.014-.816.02-1.193.02-5.151 0-6.443-1.175-6.443-1.175l-.115-2.778c-.024-.998 3.392-1.891 7.632-1.996.272-.007.54-.01.805-.01m0-1.003c-.272 0-.548.003-.828.01-2.124.052-4.128.298-5.64.694-.742.194-3 .784-2.964 2.33l.116 2.794.017.42.31.283c.264.239 1.843 1.434 7.111 1.434.387 0 .794-.007 1.224-.02 6.22-.2 8.027-1.675 8.326-1.968l.312-.306-.011-.438-.063-2.625c-.02-.784-.827-2.608-7.91-2.608Z"
        />
        <path
          fill="#FFF066"
          d="M60.787 134.087c-4.24.104-7.656.997-7.632 1.996a.446.446 0 0 0 .05.195c.07.145.212.281.42.41 1.085.669 3.927 1.097 7.25 1.015 3.343-.082 6.176-.655 7.21-1.385.278-.194.426-.4.421-.612-.024-.998-3.48-1.723-7.718-1.62h-.001Z"
        />
        <path
          fill="#FFC31A"
          d="M60.78 134.792c-3.53.087-6.406.71-6.392 1.288.014.579 2.887.977 6.416.89 3.53-.085 6.38-.624 6.367-1.203-.014-.578-2.862-1.062-6.392-.975Z"
        />
      </g>
      <g stroke-linecap="round" stroke-linejoin="round">
        <path
          fill="#FFF"
          fill-rule="nonzero"
          stroke="#0B2D42"
          stroke-width="4.992"
          d="M56 41.648h25.059C101.799 41.648 100.703 3 55.999 3 11.298 3 10.2 41.648 30.942 41.648H56Z"
        />
        <path
          fill="#07C5D1"
          fill-rule="nonzero"
          stroke="#0B2D42"
          stroke-width="4.033"
          d="M81.059 14.734c7.374 0 13.352 6.025 13.352 13.457s-5.978 13.457-13.352 13.457H30.94c-7.375 0-13.352-6.025-13.352-13.457s5.977-13.457 13.352-13.457h50.118Z"
        />
        <g stroke="#0A2E43" stroke-width="9.152">
          <path d="M75.676 28.294h-.22M36.544 28.294h-.221" />
        </g>
      </g>
      <path
        fill="#FFF"
        fill-rule="nonzero"
        stroke="#0B2D42"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="4.992"
        d="M26.658 67.055s18.071 24.43 13.122 28.259C34.63 99.297 15.033 81.49 15.033 81.49M85.201 67.055S67.13 91.485 72.08 95.314c5.15 3.983 24.748-13.823 24.748-13.823"
      />
      <g fill="#0A2E43" fill-rule="nonzero">
        <path
          d="M48.009 102.333v3.077a100.148 100.148 0 0 1-3.278-.33v9.286c-1.29-.157-2.575-.34-3.86-.548v-9.267a100.315 100.315 0 0 1-3.218-.56v-3.06a100.06 100.06 0 0 0 10.356 1.402ZM52.939 102.62v12.411a100.38 100.38 0 0 1-3.882-.23v-12.387c1.293.093 2.587.162 3.882.205ZM64.277 108.783a4.212 4.212 0 0 1-1.6 1.634c-.705.418-1.56.649-2.562.683a94.5 94.5 0 0 1-1.54.04v3.956c-1.295.021-2.59.018-3.886-.011V102.66c1.809.029 3.618.009 5.427-.06 1.516-.06 2.68.251 3.489.968.81.717 1.214 1.72 1.214 3.027a4.54 4.54 0 0 1-.542 2.187Zm-3.392-1.937c0-.763-.402-1.124-1.207-1.097-.368.011-.735.021-1.103.029v2.285c.368-.007.735-.016 1.103-.028.805-.026 1.207-.426 1.207-1.19v.001ZM67.217 113.654c-.96-.602-1.48-1.585-1.561-2.93 1.372-.123 2.743-.274 4.112-.454.058.712.371 1.031.939.952.208-.03.384-.105.53-.227a.62.62 0 0 0 .217-.501c0-.296-.157-.51-.469-.649-.313-.137-.8-.278-1.46-.428-.79-.181-1.443-.383-1.96-.597a3.246 3.246 0 0 1-1.333-1.045c-.372-.482-.552-1.131-.54-1.944 0-.811.206-1.521.619-2.133.413-.612.977-1.103 1.692-1.482.714-.378 1.517-.628 2.41-.755 1.506-.215 2.699-.064 3.581.487.883.55 1.346 1.458 1.393 2.739-1.377.264-2.757.498-4.14.705-.012-.352-.098-.593-.26-.722a.742.742 0 0 0-.591-.146.61.61 0 0 0-.4.224.742.742 0 0 0-.157.49c0 .282.154.495.461.635.307.14.796.292 1.468.449.775.176 1.414.352 1.916.537.502.185.937.484 1.306.9.369.416.553.99.553 1.723 0 .769-.184 1.494-.553 2.17-.369.677-.905 1.25-1.61 1.709-.703.46-1.542.763-2.514.9-1.472.205-2.688-.006-3.647-.608h-.002Z"
        />
      </g>
    </g>
  </svg>
</template>
