<script setup lang="ts">
import SwiftaidLogo from '@/components/SwiftaidLogo.vue'
import GiftaidItLogo from '@/components/GiftaidItLogo.vue'
import HmrcRecognisedLogo from '@/components/HmrcRecognisedLogo.vue'

const year = new Date().getFullYear()
</script>

<template>
  <footer class="mx-3" data-testid="footer">
    <div
      class="grid grid-cols-2 md:grid-cols-5 gap-8 items-center print:hidden"
    >
      <SwiftaidLogo class="mx-auto max-w-28" />
      <GiftaidItLogo class="mx-auto" />
      <HmrcRecognisedLogo class="mx-auto" />
      <img
        src="@/assets/footer/iso-27001.png"
        srcset="
          @/assets/footer/iso-27001@2x.png 2x,
          @/assets/footer/iso-27001@3x.png 3x
        "
        class="mx-auto"
        :alt="$t('StatementView.MainFooter.Iso27001LogoAlt')"
      />
      <img
        src="@/assets/footer/iso-22301.png"
        srcset="
          @/assets/footer/iso-22301@2x.png 2x,
          @/assets/footer/iso-22301@3x.png 3x
        "
        class="mx-auto col-span-2 md:col-span-1"
        :alt="$t('StatementView.MainFooter.Iso22301LogoAlt')"
      />
    </div>

    <i18n-t
      keypath="StatementView.MainFooter.CompanyInfo"
      scope="global"
      tag="p"
      class="text-sm text-center opacity-75 my-8 text-balance print:text-left print:opacity-100 print:text-xs"
      data-testid="copyright-notice"
    >
      <template #year>
        {{ year }}
      </template>
    </i18n-t>
  </footer>
</template>
