<script setup lang="ts">
import { inject } from 'vue'
import { useRoute } from 'vue-router'
import BackLink from '@/components/BackLink.vue'
import MainContainer from '@/components/MainContainer.vue'
import MainHeader from '@/components/MainHeader.vue'
import EnvelopeSvg from '@/components/EnvelopeSvg.vue'
import MainFooter from '@/components/MainFooter.vue'
import router from '@/router'
import MainError from '@/components/MainError.vue'
import TipsSection from '@/components/TipsSection.vue'
import { useRouteQueryProcessor } from '@/composables/useRouteQueryProcessor'

const { routeQuery, hasRouteError, donorData } =
  useRouteQueryProcessor(useRoute())

let errorMode = hasRouteError

const supportLink = inject('supportLink') as string

const { donorid, firstname, lastname, emailaddress } = donorData
const fullname = firstname && lastname ? `${firstname} ${lastname}` : ''

const goBack = () => {
  router.push({ name: 'statementView', query: routeQuery })
}
</script>

<template>
  <MainContainer>
    <div>
      <BackLink @click.prevent="goBack">
        {{ $t('DetailedStatementSentView.BackLink') }}
      </BackLink>
      <MainHeader>
        <EnvelopeSvg class="mx-auto max-w-32 print:mx-0" />
        <span class="sr-only">{{ $t('StatementView.ScreenReaderTitle') }}</span>
      </MainHeader>

      <MainError v-if="errorMode" />

      <main v-else class="mx-3">
        <h1
          class="text-xl md:text-2xl font-headers font-semibold text-center text-pretty mb-5 print:text-left print:text-xl"
        >
          {{ $t('DetailedStatementSentView.Title') }}
        </h1>

        <i18n-t
          keypath="DetailedStatementSentView.Description"
          scope="global"
          tag="p"
          class="text-balance text-center mb-5"
          data-testid="description"
        >
          <template #emailaddress>
            <strong>{{ emailaddress }}</strong>
          </template>
        </i18n-t>

        <div>
          <p class="text-center mt-5" data-testid="having-trouble-message">
            {{ $t('DetailedStatementSentView.HavingTrouble') }}
            <a
              :href="supportLink"
              class="text-link hover:text-blue-900 font-semibold mt-2"
              target="_blank"
            >
              {{ $t('DetailedStatementSentView.ContactSupport') }}
            </a>
          </p>
        </div>

        <TipsSection
          :name="fullname"
          :email-address="emailaddress"
          :donor-id="donorid"
        />
      </main>
    </div>

    <MainFooter class="my-20" />
  </MainContainer>
</template>
