import type { ZodError } from 'zod'
import { fromZodError } from 'zod-validation-error'
import { decompressSync, strToU8, strFromU8 } from 'fflate'
import {
  compressedDonorDataSchema,
  donorDataSchema,
  type CompressedDonorDataRouteQuery,
  type DonorData
} from '@/types'

export class RouteQueryProcessor {
  public donorData: DonorData
  public hasError: boolean
  private queryParams: CompressedDonorDataRouteQuery | undefined

  constructor() {
    this.donorData = {} as DonorData
    this.hasError = false
  }

  setRouteQuery(routeQueryObj: CompressedDonorDataRouteQuery) {
    this.queryParams = routeQueryObj
  }

  processRouteQuery() {
    if (!this.queryParams?.p) {
      this.hasError = true
      return
    }

    try {
      this.validateQueryString(this.queryParams)
      this.extractDonorDataFromQueryString(this.queryParams.p)
      this.cleanEmptyKeys()
      this.validateDonorData()
    } catch (error) {
      console.error('RouteQueryProcessor Error:', error)
      this.hasError = true
    }
  }

  /**
   * Validate compressed query string
   */
  private validateQueryString(queryParams: CompressedDonorDataRouteQuery) {
    try {
      if (queryParams.p) {
        compressedDonorDataSchema.parse(queryParams)
      }
    } catch (error) {
      throw new Error(
        `Error parsing query params: ${fromZodError(error as ZodError).toString()}`
      )
    }
  }

  /**
   * Convert base64Url to base64 string,
   * decompress and convert to DonorData object
   */
  private extractDonorDataFromQueryString(queryString: string) {
    try {
      const base64String = this.base64UrlToBase64(queryString)
      const decompressedString = this.decompressString(base64String)

      Object.fromEntries([...new URLSearchParams(decompressedString).entries()])
      this.donorData = Object.fromEntries([
        ...new URLSearchParams(decompressedString).entries()
      ]) as DonorData
    } catch (error) {
      throw new Error(`Failed to decompress query string: ${error}`)
    }
  }

  /**
   * base64Url to base64 conversion
   */
  private base64UrlToBase64(base64Url: string): string {
    return base64Url.replace(/-/g, '+').replace(/_/g, '/')
  }

  /**
   * Decompress base64 string
   */
  private decompressString(str: string): string {
    try {
      const decodedBase64String = atob(str)
      const uInt8Array = strToU8(decodedBase64String, true)
      const decompressed = decompressSync(uInt8Array)
      return strFromU8(decompressed)
    } catch (error) {
      throw new Error(`Failed to decompress string: ${error}`)
    }
  }

  /**
   * Remove any keys that have undefined, empty or NaN values
   */
  private cleanEmptyKeys() {
    try {
      Object.keys(this.donorData).forEach((key) => {
        const _key = key as keyof DonorData
        if (!this.donorData[_key]) {
          delete this.donorData[_key]
        }
      })
    } catch (error) {
      throw new Error(`Error cleaning empty keys: ${error}`)
    }
  }

  /**
   * Validate donor data
   */
  private validateDonorData() {
    try {
      donorDataSchema.parse(this.donorData)
    } catch (error) {
      throw new Error(`Error parsing donor data: ${error}`)
    }
  }
}
