import { z } from 'zod'

export const donorDataSchema = z.object({
  emailaddress: z.string().email(),
  firstname: z.string().min(1),
  lastname: z.string().min(1),
  addressline1: z.string().min(1),
  postcode: z.string().min(1),
  country: z.string().min(1),
  statementperiod: z.string().min(1),
  statementperiodstart: z.string().min(1),
  statementperiodend: z.string().min(1),
  totaldonated: z.string().min(1).or(z.number().min(1)),
  totalgiftaid: z.string().min(1).or(z.number().min(1)),
  reauthurl: z.string().min(1),
  donorid: z.string().min(1),
  token: z.string().min(1),

  // Optional fields
  addressline2: z.string().optional(),
  city: z.string().optional(),
  county: z.string().optional(),
  showreauthorisation: z.string().optional()
})

export type DonorData = z.infer<typeof donorDataSchema>

export const compressedDonorDataSchema = z.object({
  p: z.string().min(1)
})

export type CompressedDonorDataRouteQuery = z.infer<
  typeof compressedDonorDataSchema
>
