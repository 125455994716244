<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import MainContainer from '@/components/MainContainer.vue'
import MainHeader from '@/components/MainHeader.vue'
import SwiftaidLogo from '@/components/SwiftaidLogo.vue'
import MainFooter from '@/components/MainFooter.vue'
import ShadowBox from '@/components/ShadowBox.vue'
import HighlightedSection from '@/components/HighlightedSection.vue'
import PrintButton from '@/components/PrintButton.vue'
import formatCurrency from '@/utils/formatCurrency'
import MainError from '@/components/MainError.vue'
import TipsSection from '@/components/TipsSection.vue'
import { useRouteQueryProcessor } from '@/composables/useRouteQueryProcessor'
import ChevronSvg from '@/components/ChevronSvg.vue'

const { routeQuery, hasRouteError, donorData } =
  useRouteQueryProcessor(useRoute())

let errorMode = hasRouteError

const {
  donorid,
  emailaddress,
  firstname,
  lastname,
  addressline1,
  addressline2,
  city,
  county,
  postcode,
  country,
  statementperiodstart,
  statementperiodend,
  totaldonated,
  totalgiftaid,
  reauthurl,
  showreauthorisation
} = donorData

const fullname = firstname && lastname ? `${firstname} ${lastname}` : ''

const address = [addressline1, addressline2, city, county, postcode, country]
  .filter((item) => item)
  .join('<br>')

let donated = '£0'
let giftaid = '£0'
try {
  donated = formatCurrency(Number(totaldonated))
  giftaid = formatCurrency(Number(totalgiftaid))
} catch (error) {
  errorMode = true
}

const detailedStatementLink = {
  name: 'requestDetailedStatement',
  query: routeQuery
}

const showRenewAuthorisationLink = computed(() => {
  try {
    return JSON.parse(showreauthorisation?.toLowerCase() ?? 'false')
  } catch (error) {
    return false
  }
})
</script>

<template>
  <MainContainer>
    <div>
      <MainHeader>
        <SwiftaidLogo class="mx-auto max-w-32 print:mx-0 md:mb-12 md:mt-16" />
        <span class="sr-only">{{ $t('StatementView.ScreenReaderTitle') }}</span>
      </MainHeader>

      <MainError v-if="errorMode" />

      <main v-else class="mx-3">
        <i18n-t
          keypath="StatementView.Title"
          scope="global"
          tag="h1"
          class="text-xl md:text-2xl font-headers font-semibold text-center text-pretty mb-8 print:text-left print:text-xl"
        >
          <template #statementperiodend>
            {{ statementperiodend }}
          </template>
        </i18n-t>

        <ShadowBox class="md:mb-16">
          <p class="font-bold" data-testid="name">
            {{ fullname }}
          </p>
          <p class="font-bold" v-html="address" data-testid="address"></p>
          <i18n-t
            keypath="StatementView.Description"
            scope="global"
            tag="p"
            class="mt-8 mb-8 text-pretty"
            data-testid="description"
          >
            <template #statementperiodstart>
              <strong data-testid="statementperiodstart">
                {{ statementperiodstart }}
              </strong>
            </template>
            <template #statementperiodend>
              <strong data-testid="statementperiodend">
                {{ statementperiodend }}
              </strong>
            </template>
          </i18n-t>

          <HighlightedSection class="mb-8">
            <i18n-t
              keypath="StatementView.HighlightedSection.Title"
              scope="global"
              tag="h2"
              class="font-headers text-xl font-semibold mb-3 print:text-lg"
              data-testid="total-donated"
            >
              <template #donated>
                {{ donated }}
              </template>
            </i18n-t>

            <p class="text-sm mb-2 text-pretty">
              {{ $t('StatementView.HighlightedSection.Description') }}
            </p>

            <i18n-t
              keypath="StatementView.HighlightedSection.TotalGiftAid"
              scope="global"
              tag="p"
              class="text-sm text-pretty mb-2"
            >
              <template #giftaid>
                <strong data-testid="total-giftaid">{{ giftaid }}</strong>
              </template>
            </i18n-t>

            <p class="text-pretty print:hidden">
              <RouterLink
                :to="detailedStatementLink"
                class="text-link print:text-black font-semibold"
                data-testid="link-detailed-statement"
              >
                {{
                  $t(
                    'StatementView.HighlightedSection.RequestDetailedStatementText'
                  )
                }}
              </RouterLink>
            </p>
          </HighlightedSection>

          <p class="text-sm mb-2 text-pretty">
            <strong>{{ $t('StatementView.NoticeSection.Title') }}</strong>
            {{ $t('StatementView.NoticeSection.Para1') }}
          </p>

          <p class="text-sm mb-8 text-pretty">
            {{ $t('StatementView.NoticeSection.Para2') }}
          </p>

          <PrintButton />
        </ShadowBox>

        <hr class="my-8 print:hidden md:hidden" />

        <RouterLink
          :to="detailedStatementLink"
          data-testid="infolink-detailed-statement"
          class="info-link group"
        >
          <h2>
            {{ $t('StatementView.RequestDetailedStatementSection.Title') }}
            <ChevronSvg class="group-hover:fill-link" />
          </h2>
          <p>
            {{
              $t('StatementView.RequestDetailedStatementSection.Description')
            }}
          </p>
        </RouterLink>

        <hr class="my-8 print:hidden" />

        <a
          :href="reauthurl"
          data-testid="infolink-update-details"
          class="info-link group"
        >
          <h2>
            {{ $t('StatementView.UpdateDetailsSection.Title') }}
            <ChevronSvg class="group-hover:fill-link" />
          </h2>
          <p>
            {{ $t('StatementView.UpdateDetailsSection.Description') }}
          </p>
        </a>

        <hr class="my-8 print:hidden" />

        <a
          v-if="showRenewAuthorisationLink"
          :href="reauthurl"
          data-testid="infolink-renew-authorisation"
          class="info-link group"
        >
          <h2>
            {{ $t('StatementView.RenewAuthorisationSection.Title') }}
            <ChevronSvg class="group-hover:fill-link" />
          </h2>
          <p>
            {{ $t('StatementView.RenewAuthorisationSection.Description') }}
          </p>
        </a>

        <hr v-if="showRenewAuthorisationLink" class="my-8 print:hidden" />

        <TipsSection
          :name="fullname"
          :email-address="emailaddress"
          :donor-id="donorid"
        />

        <hr class="my-8 print:hidden" />
      </main>
    </div>

    <MainFooter class="my-20 print:my-8" />
  </MainContainer>
</template>

<style scoped>
.info-link {
  @apply block md:p-3 mt-8 rounded-lg print:hidden transition-colors hover:bg-blue-50;
}

.info-link h2 {
  @apply font-bold mb-2 flex justify-between items-center;
}

.info-link h2 svg {
  @apply w-4 h-4 transition-colors rotate-180;
}

.info-link p {
  @apply text-pretty;
}
</style>
