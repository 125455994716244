<template>
  <svg
    width="119px"
    height="100px"
    viewBox="0 0 119 100"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 167</title>
    <defs>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199 L5.75114496,10.6649199 Z"
        id="path-1"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199 L5.75114496,10.6649199 Z"
        id="path-3"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-5"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-7"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-9"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-11"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-13"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-15"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-17"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-19"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-21"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-23"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-25"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-27"
      ></path>
      <path
        d="M5.75114496,10.6649199 C-3.54759869,22.6631321 -1.33634389,39.9089068 10.691384,49.1851972 L10.691384,49.1851972 C22.7176896,58.4624335 40.0056817,56.2560452 49.3048994,44.258306 L49.3048994,44.258306 C58.6036431,32.2600938 56.3923883,15.0138462 44.3656086,5.73708279 L44.3656086,5.73708279 C39.3533046,1.87105525 33.4278624,0 27.5474607,0 L27.5474607,0 C19.3168897,0 11.1749775,3.66690876 5.75114496,10.6649199"
        id="path-29"
      ></path>
    </defs>
    <g
      id="☯️-Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Illustration/swifty/swifty-looking-at-form"
        transform="translate(0, -4)"
      >
        <g id="Group-167" transform="translate(2, 4)">
          <path
            d="M116.666667,93.2621677 C116.666667,95.1426821 104.59295,96.6675259 89.6987455,96.6675259 C74.8050152,96.6675259 62.7312985,95.1426821 62.7312985,93.2621677 C62.7312985,91.3816532 74.8050152,89.8568094 89.6987455,89.8568094 C104.59295,89.8568094 116.666667,91.3816532 116.666667,93.2621677"
            id="Fill-1"
            fill="#E6EAEC"
          ></path>
          <polygon
            id="Fill-3"
            fill="#FFFFFF"
            points="113.610403 91.8955785 75.290837 91.8955785 65.5905211 91.8955785 65.5905211 81.7173411 65.5905211 26.2648659 113.610403 26.2648659"
          ></polygon>
          <polygon
            id="Stroke-4"
            stroke="#0B2D42"
            stroke-width="2.138724"
            stroke-linecap="round"
            stroke-linejoin="round"
            points="113.610403 91.8955785 75.290837 91.8955785 65.5905211 91.8955785 65.5905211 81.7173411 65.5905211 26.2648659 113.610403 26.2648659"
          ></polygon>
          <path
            d="M70.4060215,35.2690115 L77.0938346,35.2690115 L70.4060215,35.2690115 Z"
            id="Fill-5"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="35.2692007"
            x2="77.093645"
            y2="35.2692007"
            id="Stroke-6"
            stroke="#0B2D42"
            stroke-width="2.138724"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,38.5236826 L77.0938346,38.5236826 L70.4060215,38.5236826 Z"
            id="Fill-7"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="38.5236826"
            x2="77.093645"
            y2="38.5236826"
            id="Stroke-8"
            stroke="#0B2D42"
            stroke-width="2.138724"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,41.7783537 L77.0938346,41.7783537 L70.4060215,41.7783537 Z"
            id="Fill-9"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="41.7782119"
            x2="77.093645"
            y2="41.7782119"
            id="Stroke-10"
            stroke="#0B2D42"
            stroke-width="2.138724"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,48.2872703 L87.9742133,48.2872703 L70.4060215,48.2872703 Z"
            id="Fill-11"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="48.2872703"
            x2="87.9740237"
            y2="48.2872703"
            id="Stroke-12"
            stroke="#0B2D42"
            stroke-width="2.138724"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,51.6756018 L106.387745,51.6756018 L70.4060215,51.6756018 Z"
            id="Fill-13"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="51.675791"
            x2="106.387556"
            y2="51.675791"
            id="Stroke-14"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,53.8699295 L104.113434,53.8699295 L70.4060215,53.8699295 Z"
            id="Fill-15"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="53.8700714"
            x2="104.113244"
            y2="53.8700714"
            id="Stroke-16"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,56.0642572 L101.705897,56.0642572 L70.4060215,56.0642572 Z"
            id="Fill-17"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="56.0643518"
            x2="101.705707"
            y2="56.0643518"
            id="Stroke-18"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,58.2585849 L104.113434,58.2585849 L70.4060215,58.2585849 Z"
            id="Fill-19"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="58.2586322"
            x2="104.113244"
            y2="58.2586322"
            id="Stroke-20"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,60.4529126 L76.2911643,60.4529126 L70.4060215,60.4529126 Z"
            id="Fill-21"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="60.4529126"
            x2="76.2914487"
            y2="60.4529126"
            id="Stroke-22"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,65.9278774 L109.972501,65.9278774 L70.4060215,65.9278774 Z"
            id="Fill-23"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="65.9280665"
            x2="109.972785"
            y2="65.9280665"
            id="Stroke-24"
            stroke="#D9D9D9"
            stroke-width="0.363447"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,74.2484445 L109.972501,74.2484445 L70.4060215,74.2484445 Z"
            id="Fill-25"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="74.2486337"
            x2="109.972785"
            y2="74.2486337"
            id="Stroke-26"
            stroke="#D9D9D9"
            stroke-width="0.363447"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,70.0883738 L109.972501,70.0883738 L70.4060215,70.0883738 Z"
            id="Fill-27"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="70.0883265"
            x2="109.972785"
            y2="70.0883265"
            id="Stroke-28"
            stroke="#D9D9D9"
            stroke-width="0.363447"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,67.9821597 L76.2911643,67.9821597 L70.4060215,67.9821597 Z"
            id="Fill-29"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="67.9823489"
            x2="76.2914487"
            y2="67.9823489"
            id="Stroke-30"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M103.444558,67.9821597 L109.330175,67.9821597 L103.444558,67.9821597 Z"
            id="Fill-31"
            fill="#FFFFFF"
          ></path>
          <line
            x1="103.444653"
            y1="67.9823489"
            x2="109.330269"
            y2="67.9823489"
            id="Stroke-32"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,72.0502858 L76.2911643,72.0502858 L70.4060215,72.0502858 Z"
            id="Fill-33"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="72.0502858"
            x2="76.2914487"
            y2="72.0502858"
            id="Stroke-34"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M103.444558,72.0502858 L109.330175,72.0502858 L103.444558,72.0502858 Z"
            id="Fill-35"
            fill="#FFFFFF"
          ></path>
          <line
            x1="103.444653"
            y1="72.0502858"
            x2="109.330269"
            y2="72.0502858"
            id="Stroke-36"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,74.2484445 L109.972501,74.2484445 L70.4060215,74.2484445 Z"
            id="Fill-37"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="74.2486337"
            x2="109.972785"
            y2="74.2486337"
            id="Stroke-38"
            stroke="#D9D9D9"
            stroke-width="0.363447"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,82.5690117 L109.972501,82.5690117 L70.4060215,82.5690117 Z"
            id="Fill-39"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="82.5691536"
            x2="109.972785"
            y2="82.5691536"
            id="Stroke-40"
            stroke="#D9D9D9"
            stroke-width="0.363447"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,78.408941 L109.972501,78.408941 L70.4060215,78.408941 Z"
            id="Fill-41"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="78.4088937"
            x2="109.972785"
            y2="78.4088937"
            id="Stroke-42"
            stroke="#D9D9D9"
            stroke-width="0.363447"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,76.3027269 L76.2911643,76.3027269 L70.4060215,76.3027269 Z"
            id="Fill-43"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="76.3029161"
            x2="76.2914487"
            y2="76.3029161"
            id="Stroke-44"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M103.444558,76.3027269 L109.330175,76.3027269 L103.444558,76.3027269 Z"
            id="Fill-45"
            fill="#FFFFFF"
          ></path>
          <line
            x1="103.444653"
            y1="76.3029161"
            x2="109.330269"
            y2="76.3029161"
            id="Stroke-46"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,80.370853 L76.2911643,80.370853 L70.4060215,80.370853 Z"
            id="Fill-47"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="80.370853"
            x2="76.2914487"
            y2="80.370853"
            id="Stroke-48"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M103.444558,80.370853 L109.330175,80.370853 L103.444558,80.370853 Z"
            id="Fill-49"
            fill="#FFFFFF"
          ></path>
          <line
            x1="103.444653"
            y1="80.370853"
            x2="109.330269"
            y2="80.370853"
            id="Stroke-50"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,86.7295081 L109.972501,86.7295081 L70.4060215,86.7295081 Z"
            id="Fill-51"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="86.7294608"
            x2="109.972785"
            y2="86.7294608"
            id="Stroke-52"
            stroke="#D9D9D9"
            stroke-width="0.363447"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M70.4060215,84.6232941 L76.2911643,84.6232941 L70.4060215,84.6232941 Z"
            id="Fill-53"
            fill="#FFFFFF"
          ></path>
          <line
            x1="70.4058319"
            y1="84.6234833"
            x2="76.2914487"
            y2="84.6234833"
            id="Stroke-54"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <path
            d="M103.444558,84.6232941 L109.330175,84.6232941 L103.444558,84.6232941 Z"
            id="Fill-55"
            fill="#FFFFFF"
          ></path>
          <line
            x1="103.444653"
            y1="84.6234833"
            x2="109.330269"
            y2="84.6234833"
            id="Stroke-56"
            stroke="#0B2D42"
            stroke-width="0.726327"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></line>
          <g id="Group-115" transform="translate(38.3785, 2.9658)">
            <g id="Group-59">
              <mask id="mask-2" fill="white">
                <use xlink:href="#path-1"></use>
              </mask>
              <g id="Clip-58"></g>
              <polygon
                id="Fill-57"
                fill="#FFFFFF"
                mask="url(#mask-2)"
                points="102.999941 135.034515 30.0256876 135.034515 11.5533657 135.034515 11.5533657 115.651878 11.5533657 10.051246 102.999941 10.051246"
              ></polygon>
            </g>
            <g id="Group-62">
              <mask id="mask-4" fill="white">
                <use xlink:href="#path-3"></use>
              </mask>
              <g id="Clip-61"></g>
              <polygon
                id="Stroke-60"
                stroke="#0B2D42"
                stroke-width="4.072761"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-4)"
                points="102.999941 135.034515 30.0256876 135.034515 11.5533657 135.034515 11.5533657 115.651878 11.5533657 10.051246 102.999941 10.051246"
              ></polygon>
            </g>
            <g id="Group-66">
              <mask id="mask-6" fill="white">
                <use xlink:href="#path-5"></use>
              </mask>
              <g id="Clip-64"></g>
              <line
                x1="20.7233881"
                y1="27.1981234"
                x2="33.4599123"
                y2="27.1981234"
                id="Fill-63"
                fill="#FFFFFF"
                mask="url(#mask-6)"
              ></line>
              <line
                x1="20.7233881"
                y1="27.1981234"
                x2="33.4599123"
                y2="27.1981234"
                id="Stroke-65"
                stroke="#0B2D42"
                stroke-width="4.072761"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-6)"
              ></line>
            </g>
            <g id="Group-70">
              <mask id="mask-8" fill="white">
                <use xlink:href="#path-7"></use>
              </mask>
              <g id="Clip-68"></g>
              <line
                x1="20.7233881"
                y1="33.3958753"
                x2="33.4599123"
                y2="33.3958753"
                id="Fill-67"
                fill="#FFFFFF"
                mask="url(#mask-8)"
              ></line>
              <line
                x1="20.7233881"
                y1="33.3958753"
                x2="33.4599123"
                y2="33.3958753"
                id="Stroke-69"
                stroke="#0B2D42"
                stroke-width="4.072761"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-8)"
              ></line>
            </g>
            <g id="Group-74">
              <mask id="mask-10" fill="white">
                <use xlink:href="#path-9"></use>
              </mask>
              <g id="Clip-72"></g>
              <line
                x1="20.7233881"
                y1="39.5936273"
                x2="33.4599123"
                y2="39.5936273"
                id="Fill-71"
                fill="#FFFFFF"
                mask="url(#mask-10)"
              ></line>
              <line
                x1="20.7233881"
                y1="39.5936273"
                x2="33.4599123"
                y2="39.5936273"
                id="Stroke-73"
                stroke="#0B2D42"
                stroke-width="4.072761"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-10)"
              ></line>
            </g>
            <g id="Group-78">
              <mask id="mask-12" fill="white">
                <use xlink:href="#path-11"></use>
              </mask>
              <g id="Clip-76"></g>
              <line
                x1="20.7233881"
                y1="51.989084"
                x2="54.1795215"
                y2="51.989084"
                id="Fill-75"
                fill="#FFFFFF"
                mask="url(#mask-12)"
              ></line>
              <line
                x1="20.7233881"
                y1="51.989084"
                x2="54.1795215"
                y2="51.989084"
                id="Stroke-77"
                stroke="#0B2D42"
                stroke-width="4.072761"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-12)"
              ></line>
            </g>
            <g id="Group-82">
              <mask id="mask-14" fill="white">
                <use xlink:href="#path-13"></use>
              </mask>
              <g id="Clip-80"></g>
              <line
                x1="20.7233881"
                y1="58.4420014"
                x2="89.2447884"
                y2="58.4420014"
                id="Fill-79"
                fill="#FFFFFF"
                mask="url(#mask-14)"
              ></line>
              <line
                x1="20.7233881"
                y1="58.4420014"
                x2="89.2447884"
                y2="58.4420014"
                id="Stroke-81"
                stroke="#0B2D42"
                stroke-width="1.38348"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-14)"
              ></line>
            </g>
            <g id="Group-86">
              <mask id="mask-16" fill="white">
                <use xlink:href="#path-15"></use>
              </mask>
              <g id="Clip-84"></g>
              <line
                x1="20.7233881"
                y1="62.6206597"
                x2="84.9142564"
                y2="62.6206597"
                id="Fill-83"
                fill="#FFFFFF"
                mask="url(#mask-16)"
              ></line>
              <line
                x1="20.7233881"
                y1="62.6206597"
                x2="84.9142564"
                y2="62.6206597"
                id="Stroke-85"
                stroke="#0B2D42"
                stroke-width="1.38348"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-16)"
              ></line>
            </g>
            <g id="Group-90">
              <mask id="mask-18" fill="white">
                <use xlink:href="#path-17"></use>
              </mask>
              <g id="Clip-88"></g>
              <line
                x1="20.7233881"
                y1="66.7993654"
                x2="80.3291266"
                y2="66.7993654"
                id="Fill-87"
                fill="#FFFFFF"
                mask="url(#mask-18)"
              ></line>
              <line
                x1="20.7233881"
                y1="66.7993654"
                x2="80.3291266"
                y2="66.7993654"
                id="Stroke-89"
                stroke="#0B2D42"
                stroke-width="1.38348"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-18)"
              ></line>
            </g>
            <g id="Group-94">
              <mask id="mask-20" fill="white">
                <use xlink:href="#path-19"></use>
              </mask>
              <g id="Clip-92"></g>
              <line
                x1="20.7233881"
                y1="70.9780237"
                x2="84.9142564"
                y2="70.9780237"
                id="Fill-91"
                fill="#FFFFFF"
                mask="url(#mask-20)"
              ></line>
              <line
                x1="20.7233881"
                y1="70.9780237"
                x2="84.9142564"
                y2="70.9780237"
                id="Stroke-93"
                stroke="#0B2D42"
                stroke-width="1.38348"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-20)"
              ></line>
            </g>
            <g id="Group-98">
              <mask id="mask-22" fill="white">
                <use xlink:href="#path-21"></use>
              </mask>
              <g id="Clip-96"></g>
              <line
                x1="20.7233881"
                y1="75.1567293"
                x2="31.9313777"
                y2="75.1567293"
                id="Fill-95"
                fill="#FFFFFF"
                mask="url(#mask-22)"
              ></line>
              <line
                x1="20.7233881"
                y1="75.1567293"
                x2="31.9313777"
                y2="75.1567293"
                id="Stroke-97"
                stroke="#0B2D42"
                stroke-width="1.38348"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-22)"
              ></line>
            </g>
            <g id="Group-102">
              <mask id="mask-24" fill="white">
                <use xlink:href="#path-23"></use>
              </mask>
              <g id="Clip-100"></g>
              <line
                x1="20.7233881"
                y1="85.5832741"
                x2="96.0724643"
                y2="85.5832741"
                id="Fill-99"
                fill="#FFFFFF"
                mask="url(#mask-24)"
              ></line>
              <line
                x1="20.7233881"
                y1="85.5832741"
                x2="96.0724643"
                y2="85.5832741"
                id="Stroke-101"
                stroke="#D9D9D9"
                stroke-width="0.69174"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-24)"
              ></line>
            </g>
            <g id="Group-106">
              <mask id="mask-26" fill="white">
                <use xlink:href="#path-25"></use>
              </mask>
              <g id="Clip-104"></g>
              <line
                x1="20.7233881"
                y1="93.5058873"
                x2="96.0724643"
                y2="93.5058873"
                id="Fill-103"
                fill="#FFFFFF"
                mask="url(#mask-26)"
              ></line>
              <line
                x1="20.7233881"
                y1="93.5058873"
                x2="96.0724643"
                y2="93.5058873"
                id="Stroke-105"
                stroke="#D9D9D9"
                stroke-width="0.69174"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-26)"
              ></line>
            </g>
            <g id="Group-110">
              <mask id="mask-28" fill="white">
                <use xlink:href="#path-27"></use>
              </mask>
              <g id="Clip-108"></g>
              <line
                x1="20.7233881"
                y1="89.4953686"
                x2="31.9313777"
                y2="89.4953686"
                id="Fill-107"
                fill="#FFFFFF"
                mask="url(#mask-28)"
              ></line>
              <line
                x1="20.7233881"
                y1="89.4953686"
                x2="31.9313777"
                y2="89.4953686"
                id="Stroke-109"
                stroke="#0B2D42"
                stroke-width="1.38348"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-28)"
              ></line>
            </g>
            <g id="Group-114">
              <mask id="mask-30" fill="white">
                <use xlink:href="#path-29"></use>
              </mask>
              <g id="Clip-112"></g>
              <line
                x1="20.7233881"
                y1="97.2421802"
                x2="31.9313777"
                y2="97.2421802"
                id="Fill-111"
                fill="#FFFFFF"
                mask="url(#mask-30)"
              ></line>
              <line
                x1="20.7233881"
                y1="97.2421802"
                x2="31.9313777"
                y2="97.2421802"
                id="Stroke-113"
                stroke="#0B2D42"
                stroke-width="1.38348"
                stroke-linecap="round"
                stroke-linejoin="round"
                mask="url(#mask-30)"
              ></line>
            </g>
          </g>
          <g id="Group-166">
            <g id="Group-119" transform="translate(30.9859, 58.7711)">
              <path
                d="M1.77270191,9.24209153 C1.77270191,9.24209153 13.8881404,4.60370983 13.4500616,1.64719673 C13.0793066,-0.857160472 0,0.238702727 0,0.238702727"
                id="Fill-116"
                fill="#FFFFFF"
              ></path>
              <path
                d="M1.77270191,9.24209153 C1.77270191,9.24209153 13.8881404,4.60370983 13.4500616,1.64719673 C13.0793066,-0.857160472 0,0.238702727 0,0.238702727"
                id="Stroke-118"
                stroke="#0B2D42"
                stroke-width="2.30202"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <path
              d="M44.799416,96.5946418 C44.799416,98.4751563 35.8206974,100 24.7445107,100 C13.6683241,100 4.68960552,98.4751563 4.68960552,96.5946418 C4.68960552,94.7141273 13.6683241,93.1892835 24.7445107,93.1892835 C35.8206974,93.1892835 44.799416,94.7141273 44.799416,96.5946418"
              id="Fill-120"
              fill="#E6EAEC"
            ></path>
            <path
              d="M6.21392055,77.0364397 C3.62290265,87.6781842 4.63228547,96.5945472 21.8226106,96.5945472 C41.4166797,96.5945472 47.1875617,89.8741673 46.0293079,78.7320243 C44.8776917,67.6490022 39.7075087,58.5571686 28.5383963,58.5571686 C17.3692838,58.5571686 8.80493845,66.3946952 6.21392055,77.0364397"
              id="Fill-122"
              fill="#FFFFFF"
            ></path>
            <path
              d="M6.21392055,77.0364397 C3.62290265,87.6781842 4.63228547,96.5945472 21.8226106,96.5945472 C41.4166797,96.5945472 47.1875617,89.8741673 46.0293079,78.7320243 C44.8776917,67.6490022 39.7075087,58.5571686 28.5383963,58.5571686 C17.3692838,58.5571686 8.80493845,66.3946952 6.21392055,77.0364397 Z"
              id="Stroke-124"
              stroke="#0B2D42"
              stroke-width="2.30202"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M25.9132898,60.3808326 L33.7683647,56.7720988 C44.4154807,50.0091519 30.1049072,38.6291068 5.99635091,51.1863653 C-9.83090444,60.1533357 9.86083158,67.8035676 17.1313274,64.4421952 L25.9132898,60.3808326 Z"
              id="Fill-126"
              fill="#FFFFFF"
            ></path>
            <path
              d="M25.9132898,60.3808326 L33.7683647,56.7720988 C44.4154807,50.0091519 30.1049072,38.6291068 5.99635091,51.1863653 C-9.83090444,60.1533357 9.86083158,67.8035676 17.1313274,64.4421952 L25.9132898,60.3808326 Z"
              id="Stroke-128"
              stroke="#0B2D42"
              stroke-width="2.30202"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M17.1314222,64.4421479 C14.5465677,65.637807 11.4800163,64.5164037 10.2814631,61.9382637 C9.08338398,59.3596508 10.2070276,56.3005039 12.7918821,55.1048448 L30.3577033,46.9825925 C32.9344979,45.7708525 36.008161,46.8723913 37.222834,49.4429638 C38.4379811,52.0135363 37.3333019,55.0797776 34.7565073,56.2919906 C34.7365947,56.3009769 34.716682,56.3104362 34.6962952,56.3194226 L17.1314222,64.4421479 Z"
              id="Fill-130"
              fill="#07C5D1"
            ></path>
            <path
              d="M17.1314222,64.4421479 C14.5465677,65.637807 11.4800163,64.5164037 10.2814631,61.9382637 C9.08338398,59.3596508 10.2070276,56.3005039 12.7918821,55.1048448 L30.3577033,46.9825925 C32.9344979,45.7708525 36.008161,46.8723913 37.222834,49.4429638 C38.4379811,52.0135363 37.3333019,55.0797776 34.7565073,56.2919906 C34.7365947,56.3009769 34.716682,56.3104362 34.6962952,56.3194226 L17.1314222,64.4421479 Z"
              id="Stroke-132"
              stroke="#0B2D42"
              stroke-width="1.726515"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <polygon
              id="Fill-134"
              fill="#9D9998"
              points="41.2801831 65.5002684 38.0799323 63.0318567 47.1951949 51.2706006 50.3959198 53.7394854"
            ></polygon>
            <polygon
              id="Stroke-136"
              stroke="#0B2D42"
              stroke-width="2.30202"
              points="41.2801831 65.5002684 38.0799323 63.0318567 47.1951949 51.2706006 50.3959198 53.7394854"
            ></polygon>
            <polygon
              id="Fill-138"
              fill="#9D9998"
              points="39.228846 63.9183849 38.4527259 63.3196094 47.5679885 51.5583534 48.3445827 52.1576019"
            ></polygon>
            <path
              d="M18.4256984,98.0310408 C18.6096536,97.9785415 18.7741702,97.8707052 18.8917498,97.7198289 L18.8931721,97.717937 L18.892698,97.717937 L47.6717241,60.5853432 C47.2255854,60.0560938 46.7367767,59.5821815 46.2318482,59.1271878 C45.7273939,58.672667 45.2087162,58.2380109 44.6715482,57.8255842 C44.1353284,57.4112656 43.5825147,57.0196494 43.0150035,56.6474248 C42.4470183,56.2747273 41.8648097,55.9214214 41.2394569,55.6239255 L12.4599568,92.7565193 L12.4590086,92.7560463 C12.341429,92.9078685 12.2778981,93.0951632 12.2741052,93.2871876 C12.2527702,94.3220381 12.4604309,95.0617576 12.742053,95.7064108 C13.062078,96.3988337 13.4963639,96.943691 14.0325837,97.3575366 C14.5659589,97.774693 15.2022162,98.0575269 15.9546307,98.1923223 C16.650626,98.3029965 17.4224791,98.3167125 18.4256984,98.0310408"
              id="Fill-140"
              fill="#0B2D42"
            ></path>
            <path
              d="M18.4256984,98.0310408 C18.6096536,97.9785415 18.7741702,97.8707052 18.8917498,97.7198289 L18.8931721,97.717937 L18.892698,97.717937 L47.6717241,60.5853432 C47.2255854,60.0560938 46.7367767,59.5821815 46.2318482,59.1271878 C45.7273939,58.672667 45.2087162,58.2380109 44.6715482,57.8255842 C44.1353284,57.4112656 43.5825147,57.0196494 43.0150035,56.6474248 C42.4470183,56.2747273 41.8648097,55.9214214 41.2394569,55.6239255 L12.4599568,92.7565193 L12.4590086,92.7560463 C12.341429,92.9078685 12.2778981,93.0951632 12.2741052,93.2871876 C12.2527702,94.3220381 12.4604309,95.0617576 12.742053,95.7064108 C13.062078,96.3988337 13.4963639,96.943691 14.0325837,97.3575366 C14.5659589,97.774693 15.2022162,98.0575269 15.9546307,98.1923223 C16.650626,98.3029965 17.4224791,98.3167125 18.4256984,98.0310408 Z"
              id="Stroke-142"
              stroke="#0B2D42"
              stroke-width="2.30202"
            ></path>
            <path
              d="M87.6834883,47.2239945 C78.3842706,59.2217338 61.0962785,61.4281221 49.0694988,52.1513587 C37.042245,42.8745953 34.8309902,25.6288207 44.1297339,13.6306085 C53.4284775,1.63286927 70.7169437,-0.573519083 82.7441975,8.70277135 C94.7709772,17.9795347 96.982232,35.2262553 87.6834883,47.2239945"
              id="Fill-144"
              fill-opacity="0.28"
              fill="#CEE1F4"
            ></path>
            <path
              d="M65.9363379,4.99485649 C57.9560976,4.99485649 50.5912536,8.59223909 45.7301911,14.8647198 C41.5703392,20.2324157 39.7559155,26.905026 40.6211685,33.6542568 C41.4868956,40.4030147 44.9265726,46.4054315 50.3067869,50.5552389 C54.8046802,54.0244476 60.1891615,55.8590844 65.877074,55.8590844 L65.8789704,55.8590844 C73.8577884,55.8590844 81.2216841,52.2617018 86.0832207,45.990167 C90.2430726,40.6224711 92.0574963,33.9498608 91.1922433,27.200157 C90.3265162,20.4513992 86.8868392,14.4489823 81.5066249,10.2991749 C77.0077834,6.82949324 71.6237762,4.99485649 65.9363379,4.99485649 M65.8751775,59.8944339 C59.2845572,59.8944339 53.0457275,57.7689228 47.8319263,53.7472893 C41.5973635,48.9386397 37.6115104,41.9846144 36.6087651,34.1660065 C35.606494,26.3469256 37.7091773,18.6158165 42.5294662,12.3958351 C48.1628559,5.12823302 56.6949617,0.959979946 65.9387084,0.959979946 C72.5293287,0.959979946 78.7681584,3.08549105 83.9819597,7.1061786 C96.8711477,17.0488787 99.2497638,35.5995677 89.2844197,48.4581058 C83.65103,55.7261808 75.1189243,59.8944339 65.8751775,59.8944339"
              id="Fill-146"
              fill="#93908F"
            ></path>
            <g id="Group-150" transform="translate(35.402, 0)" fill="#0B2D42">
              <path
                d="M30.4749617,54.8991044 C25.0022957,54.8991044 19.8216822,53.1344667 15.4930466,49.7957967 C4.78761496,41.538276 2.81199345,26.1318678 11.0895015,15.452286 C15.7670829,9.41676146 22.8545718,5.95512022 30.5342256,5.95512022 C36.0068915,5.95512022 41.187505,7.7202309 45.5161406,11.0589009 C50.6929611,15.0521564 54.0027317,20.8275493 54.8357451,27.3223243 C55.6682844,33.8170992 53.9226069,40.2380913 49.9196857,45.4024117 C45.2421043,51.4379362 38.1550895,54.8991044 30.4749617,54.8991044 M30.5380184,0 L30.5380184,1.92024367 L30.5375443,0 L30.5370702,0 C20.992737,0 12.182802,4.3044674 6.36640537,11.8085527 C1.38871152,18.2309637 -0.782717936,26.2141639 0.25226689,34.2877007 C1.28725171,42.3607646 5.40348545,49.5413408 11.8414419,54.5070153 C17.224975,58.6596605 23.6676725,60.8546976 30.4730652,60.8546976 C40.0169244,60.8546976 48.8268593,56.5502302 54.6437301,49.045199 C64.9338403,35.7676127 62.47747,16.6134185 49.1682194,6.34673641 C43.7846863,2.1945642 37.3424629,0 30.5380184,0 M30.4749617,56.8193481 C38.3959381,56.8193481 46.233945,53.2971672 51.442531,46.5767873 C60.3690973,35.0595819 58.2384414,18.4447445 46.6933588,9.53973273 C41.8849226,5.83073005 36.188002,4.03487654 30.5342256,4.03487654 C22.6137233,4.03487654 14.7752422,7.55705749 9.56665621,14.2774374 C0.640563991,25.7946427 2.77121988,42.4099531 14.3158284,51.3144919 C19.1242646,55.0234946 24.8211852,56.8193481 30.4749617,56.8193481 M30.5370702,1.92024367 C36.6445712,1.92024367 42.7961644,3.859406 47.9910012,7.86543159 C60.4610749,17.4850956 62.7624108,35.4303876 53.1208848,47.8708234 C47.4950809,55.1289661 39.0274542,58.9344539 30.4730652,58.9344539 C24.3650902,58.9344539 18.2134969,56.9952916 13.0191342,52.9878471 C0.547638189,43.369602 -1.75322363,25.423837 7.88925066,12.9829283 C13.5145805,5.72525854 21.9822071,1.92024367 30.5370702,1.92024367"
                id="Fill-148"
              ></path>
            </g>
            <g
              id="Group-155"
              transform="translate(37.3977, 1.9079)"
              fill="#93908F"
            >
              <path
                d="M12.2760674,50.0149823 C23.8211501,58.9195211 40.4757295,56.794483 49.4018217,45.2768047 C52.7509435,40.9557834 54.5435581,35.9158532 54.8664278,30.8366668 C54.4416241,35.7049102 52.6693963,40.511195 49.4563445,44.6567457 C40.5297782,56.173951 23.8747246,58.2989891 12.3301161,49.3939774 C5.11698773,43.8304733 1.57869559,35.2584299 2.11586362,26.7984795 C1.36060442,35.4566028 4.88609556,44.314791 12.2760674,50.0149823"
                id="Fill-151"
              ></path>
              <path
                d="M5.84816208,11.6835132 C15.4901623,-0.756922577 33.4803131,-3.05222862 45.9503868,6.56696246 C53.7414567,12.5755279 57.5632673,21.8362104 56.982007,30.9739217 C57.7808844,21.6370916 53.972823,12.0916832 46.0044354,5.94595755 C33.5338876,-3.67276057 15.5442109,-1.37698156 5.90268487,11.0634542 C2.28474204,15.7311599 0.348945869,21.1735845 0,26.6609409 C0.451353895,21.3850005 2.36676328,16.1748024 5.84816208,11.6835132"
                id="Fill-153"
              ></path>
            </g>
            <path
              d="M70.6326467,6.69275865 C70.8758658,6.72113663 71.026159,6.75187945 71.2044248,6.78404117 C71.3732084,6.81762178 71.5367768,6.85214833 71.699397,6.88998565 C72.0222667,6.96282247 72.3356542,7.04369973 72.6452489,7.13119852 C73.2625417,7.30572313 73.8637148,7.50436903 74.4525609,7.72382545 C75.6288309,8.16415719 76.7581638,8.68773101 77.837241,9.28745244 C79.9944471,10.4859494 81.9454149,11.9937663 83.6010113,13.7323909 C85.2604006,15.4700695 86.6168329,17.4338261 87.6627222,19.505419 C88.7119304,21.5774849 89.4543886,23.7540764 89.9550501,25.9533702 C89.0077758,23.9044797 87.8485738,21.9761956 86.4708066,20.2645301 C85.0963581,18.5523917 83.5038185,17.0625474 81.7533998,15.8673613 C80.0044035,14.6707562 78.1003728,13.7650256 76.1361299,13.1638852 C75.1532973,12.8630786 74.1548191,12.6370006 73.1591855,12.490381 C72.662791,12.4165983 72.1649742,12.3631531 71.6766396,12.3319373 C71.4329465,12.3172753 71.192098,12.307816 70.9569388,12.3054512 L70.6155787,12.307816 C70.5141189,12.3111268 70.382316,12.3158564 70.3458094,12.3286265 L70.6326467,6.69275865 Z"
              id="Fill-156"
              fill="#CEE1F4"
              opacity="0.541201637"
            ></path>
            <g id="Group-161" transform="translate(17.2066, 67.6152)">
              <path
                d="M5.67037037,0 C5.67037037,0 13.9047343,10.2865467 11.7058064,12.0748327 C9.41774557,13.9359556 0,6.18971156 0,6.18971156"
                id="Fill-158"
                fill="#FFFFFF"
              ></path>
              <path
                d="M5.67037037,0 C5.67037037,0 13.9047343,10.2865467 11.7058064,12.0748327 C9.41774557,13.9359556 0,6.18971156 0,6.18971156"
                id="Stroke-160"
                stroke="#0B2D42"
                stroke-width="2.30202"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <line
              x1="19.2945547"
              y1="57.4444678"
              x2="19.3756277"
              y2="57.4104142"
              id="Stroke-162"
              stroke="#0A2E43"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></line>
            <line
              x1="32.1492748"
              y1="51.5752383"
              x2="32.2303479"
              y2="51.5411847"
              id="Stroke-164"
              stroke="#0A2E43"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></line>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
