import { onMounted } from 'vue'
import {
  cookieTools,
  addBar,
  hideBar,
  addGaScripts
} from '@streeva/very-simple-cookie-consent'
import '~cookieconsent/dist/style.css'

const getBaseDomain = () => {
  const hostname = window.location.hostname
  if (hostname.includes('localhost')) return 'localhost'
  if (hostname.includes('onrender.com')) return hostname

  const match = hostname.match(/\w*\.*?(com|co.uk|net)$/gi)
  let domain = ''

  if (match) {
    domain = match[0]
  } else {
    domain = hostname
  }

  return `.${domain}`
}

const TAG = import.meta.env.VITE_GOOGLE_TAG
const privacyPolicyLink = 'https://www.swiftaid.co.uk/legal/cookies/'
const ct = cookieTools.init({
  domain: getBaseDomain(),
  expires: 365,
  sameSite: 'Lax'
})

/**
 * Approve consent handler
 *
 * @param e
 */
const approveHandler = (e: any) => {
  e.preventDefault()
  hideBar()
  ct.approve()
  // load GA script
  addGaScripts(TAG)
}
/**
 * Decline consent handler
 *
 * @param e
 */
const declineHandler = (e: any) => {
  e.preventDefault()
  hideBar()
  ct.decline()
}

export default function useCookieConsent() {
  if (import.meta.env.PROD) {
    if (TAG === undefined) {
      console.warn('Missing Google Tag Manager ID')
    } else {
      onMounted(() => {
        // Check has approved or declined cookies
        if (!ct.hasMadeChoice()) {
          // Show consent bar
          addBar(privacyPolicyLink, approveHandler, declineHandler)
          return
        }

        // Check has approved cookies
        if (ct.hasConsent()) {
          // load GA scripts
          addGaScripts(TAG)
          return
        }
      })
    }
  }
}
