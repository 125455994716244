<template>
  <svg
    width="78px"
    height="80px"
    viewBox="0 0 78 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>envelope</title>
    <g
      id="symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Illustration/page-header/detailed-statement-email"
        transform="translate(-15, -1)"
      >
        <g id="envelope" transform="translate(14.9999, 1)">
          <path
            d="M7.15883669e-05,32.8757942 L32.219132,2.65261745 C35.9895123,-0.884205817 41.8590425,-0.884205817 45.6296018,2.65261745 L77.8484832,32.8757942"
            id="Fill-1"
            fill="#B2B2B2"
          ></path>
          <path
            d="M77.8484832,32.8757942 L7.15883669e-05,32.8757942 L77.8484832,32.8757942 Z"
            id="Fill-3"
            fill="#0CC6D1"
          ></path>
          <polygon
            id="Fill-5"
            fill="#6F6F6F"
            points="30.8244116 51.1959195 47.3715257 51.0677763 52.2885727 55.7260313 77.8484832 32.8757405 7.15883669e-05 32.8757405 25.7675884 55.9114452"
          ></polygon>
          <g transform="translate(12.0001, 10)">
            <polygon
              id="Fill-1"
              fill="#EFEFEF"
              points="0 0 -5.99520433e-15 59.7285156 54 59.7285156 54 19.0054016 34.9411765 0"
            ></polygon>
            <polygon
              id="Fill-2"
              fill="#D4D4D4"
              points="35 0 35 19 54 19"
            ></polygon>
            <polygon
              id="Fill-3"
              fill="#8A8A8A"
              points="9 31 44 31 44 25 9 25"
            ></polygon>
            <polygon
              id="Fill-3"
              fill="#8A8A8A"
              points="9 19 20 19 20 13 9 13"
            ></polygon>
            <path
              d="M11,49 L42,49 C43.1045695,49 44,48.1045695 44,47 L44,39 C44,37.8954305 43.1045695,37 42,37 L11,37 C9.8954305,37 9,37.8954305 9,39 L9,47 C9,48.1045695 9.8954305,49 11,49 Z"
              id="Fill-4"
              fill="#62ADFF"
            ></path>
            <polygon
              id="Fill-5"
              fill="#868E96"
              points="9 61 44 61 44 55 9 55"
            ></polygon>
          </g>
          <polygon
            id="Fill-9"
            fill="#C0C0C0"
            points="7.15883669e-05 32.8757942 7.15883669e-05 32.8757942 7.15883669e-05 79.9406711 25.7675884 55.9114989"
          ></polygon>
          <polygon
            id="Fill-10"
            fill="#C0C0C0"
            points="52.2885011 55.7259597 77.8484116 79.9405459 77.8484116 32.8758479"
          ></polygon>
          <polygon
            id="Fill-11"
            fill="#D1D1D1"
            points="52.2885011 55.7259597 47.3714541 51.0678837 30.82434 51.1958479 25.7675168 55.9115526 0 79.9405459 77.8484116 79.9405459"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
</template>
