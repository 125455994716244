import { createRouter, createWebHistory } from 'vue-router'
import StatementView from '../views/StatementView.vue'
import RequestDetailedStatementView from '../views/RequestDetailedStatementView.vue'
import DetailedStatementSentView from '../views/DetailedStatementSentView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'statementView',
      component: StatementView
    },
    {
      path: '/request-detailed-statement',
      name: 'requestDetailedStatement',
      component: RequestDetailedStatementView,
      meta: {
        title: 'Request Detailed Statement',
        description: 'Request a detailed statement'
      }
    },
    {
      path: '/detailed-statement-sent',
      name: 'detailedStatementSent',
      component: DetailedStatementSentView,
      meta: {
        title: 'Detailed Statement Sent',
        description: 'Your detailed statement has been sent'
      }
    },
    {
      path: '/:catchAll(.*)',
      redirect: '/'
    }
  ]
})

router.afterEach((to) => {
  if (document === null) return

  const DEFAULT_TITLE = 'Swiftaid Donor Statements'
  const DEFAULT_DESCRIPTION =
    'Help causes you donate to claim Gift Aid by signing up with Swiftaid for free!'

  let title = DEFAULT_TITLE
  if (to.meta.title) {
    title = `${to.meta.title} | ${DEFAULT_TITLE}`
  }
  document.title = title
  document
    ?.querySelector('meta[property="og:title"]')
    ?.setAttribute('content', title)
  document
    ?.querySelector('meta[name="twitter:title"]')
    ?.setAttribute('content', title)

  let description: string = DEFAULT_DESCRIPTION
  if (to.meta.description) {
    description = `${to.meta.description}`
  }
  document
    ?.querySelector('meta[name="description"]')
    ?.setAttribute('content', description)
  document
    ?.querySelector('meta[property="og:description"]')
    ?.setAttribute('content', description)
  document
    ?.querySelector('meta[name="twitter:description"]')
    ?.setAttribute('content', description)
})

export default router
