export function useStripePaymentLink(
  emailAddress: string,
  donorId: string
): URL {
  if (!import.meta.env.VITE_STRIPE_PAYMENT_LINK_URL) {
    throw new Error('Missing required environment variable')
  }

  const url = new URL(import.meta.env.VITE_STRIPE_PAYMENT_LINK_URL)
  url.searchParams.set('prefilled_email', emailAddress)
  url.searchParams.set('client_reference_id', donorId)

  return url
}
