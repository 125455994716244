import { ref } from 'vue'

export async function useRequestDetailedStatement(
  donorid: string,
  token: string,
  statementPeriod: string
) {
  const errorMessage = ref('')

  try {
    const response = await fetch(
      `${import.meta.env.VITE_CORE_BASE_URL}/donors/${donorid}/detailedstatementrequests`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-EMAIL-TOKEN': token
        },
        body: JSON.stringify({ statementPeriod })
      }
    )

    if (response.ok) {
      errorMessage.value = ''
    } else {
      switch (response.status) {
        case 400:
          errorMessage.value = 'BadRequest'
          break
        case 401:
          errorMessage.value = 'Unauthorized'
          break
        case 404:
          errorMessage.value = 'NotFound'
          break
        case 429:
          errorMessage.value = 'TooManyRequests'
          break
        case 500:
          errorMessage.value = 'Server'
          break
        default:
          errorMessage.value = 'Unknown'
          break
      }
    }
  } catch (error) {
    errorMessage.value = 'Unknown'
  }

  return { errorMessage }
}
