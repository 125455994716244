<script setup lang="ts">
import { ref, inject } from 'vue'
import { useRoute } from 'vue-router'
import BackLink from '@/components/BackLink.vue'
import MainContainer from '@/components/MainContainer.vue'
import MainHeader from '@/components/MainHeader.vue'
import SwiftyInvestigatingSvg from '@/components/SwiftyInvestigatingSvg.vue'
import MainFooter from '@/components/MainFooter.vue'
import { useI18n } from 'vue-i18n'
import { useRequestDetailedStatement } from '@/composables/useRequestDetailedStatement'
import router from '@/router'
import MainError from '@/components/MainError.vue'
import { useRouteQueryProcessor } from '@/composables/useRouteQueryProcessor'

const { routeQuery, hasRouteError, donorData } =
  useRouteQueryProcessor(useRoute())

let errorMode = hasRouteError

const { t } = useI18n()

const supportLink = inject('supportLink') as string

const sendingRequest = ref(false)
const requestErrorMessage = ref('')
const requestSuccess = ref(false)

const {
  donorid,
  token,
  emailaddress,
  statementperiod: statementPeriod
} = donorData

const sendRequest = async () => {
  sendingRequest.value = true

  try {
    const { errorMessage } = await useRequestDetailedStatement(
      donorid,
      token,
      statementPeriod
    )

    requestSuccess.value = errorMessage.value.length === 0

    if (errorMessage.value.length) {
      requestErrorMessage.value = t(
        `RequestDetailedStatementView.RequestErrors.${errorMessage.value}`
      )
      throw new Error(`Failed to send request: ${errorMessage.value}`)
    } else {
      router.push({
        name: 'detailedStatementSent',
        query: routeQuery
      })
    }
  } catch (error) {
    console.error('Failed to send request:', error)
  } finally {
    sendingRequest.value = false
  }
}

const goBack = () => {
  router.push({ name: 'statementView', query: routeQuery })
}
</script>

<template>
  <MainContainer>
    <div>
      <BackLink @click.prevent="goBack">
        {{ $t('RequestDetailedStatementView.BackLink') }}
      </BackLink>
      <MainHeader>
        <SwiftyInvestigatingSvg class="mx-auto max-w-32 print:mx-0" />
        <span class="sr-only">{{ $t('StatementView.ScreenReaderTitle') }}</span>
      </MainHeader>

      <MainError v-if="errorMode" />

      <main v-else class="mx-3">
        <h1
          class="text-xl md:text-2xl font-headers font-semibold text-center text-pretty mb-5 print:text-left print:text-xl"
        >
          {{ $t('RequestDetailedStatementView.Title') }}
        </h1>

        <i18n-t
          keypath="RequestDetailedStatementView.Description"
          scope="global"
          tag="p"
          class="text-pretty text-center mb-5"
          data-testid="description"
        >
          <template #emailaddress>
            <strong>{{ emailaddress }}</strong>
          </template>
        </i18n-t>

        <button
          class="flex mx-auto bg-primary hover:bg-sky-600 text-white font-semibold px-5 py-2 rounded-md transition-colors duration-200 ease-in-out"
          :class="sendingRequest ? 'cursor-not-allowed opacity-50' : ''"
          :disabled="sendingRequest"
          @click="sendRequest"
          data-testid="send-request-button"
        >
          <span v-if="sendingRequest" class="flex items-center">
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            {{ $t('RequestDetailedStatementView.SendingRequestButtonText') }}
          </span>
          <span v-else>
            {{ $t('RequestDetailedStatementView.SendRequestButtonText') }}
          </span>
        </button>

        <div v-if="requestErrorMessage.length">
          <p
            class="text-center text-red-600 text-sm mt-5"
            data-testid="request-error-message"
          >
            {{ requestErrorMessage }}
          </p>

          <a
            :href="supportLink"
            class="block text-center text-link hover:text-blue-900 font-semibold text-sm mt-2"
            target="_blank"
          >
            {{ $t('RequestDetailedStatementView.ContactSupport') }}
          </a>
        </div>
      </main>
    </div>

    <MainFooter class="my-20" />
  </MainContainer>
</template>
